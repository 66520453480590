import React, { useEffect, useState } from "react";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';

import {
    CDBNavbar,
    CDBNavBrand,
    CDBNavbarNav,
    CDBNavToggle,
    CDBNavItem,
    CDBNavLink,
    CDBBtn,
    CDBCollapse
} from "cdbreact";
import '../../Profile.css';
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ADMIN_URL, ORGANIZATION_URL, REQUIREMENT_URL } from "../../../constant";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { ApiCall } from "../../../Config/API";
import swal from "sweetalert";
import moment from "moment";
import { Search_PageDrop } from "../../../Components/Search_PageDrop";
import { PaginationContent } from "../../../Components/PaginationContent";

// Admin page here as hero

export const Recruiter_requirement_View = () => {




    const navigate = useHistory()

    const location = useLocation();

    const [fillabledata, setFillabledata] = useState({
        // "client_name": "",
        // "HeadRecruiter_id": '',
        // "title": "",
        // "location": "",
        // "job_type_id": '',
        // "requirement_rate_type_id": '',
        // "HeadRecruiter_price": '',
        // "visa_id": '',
        // "is_local_candidate_only": '',
        // "contract_length": '',
        // "position": '',
        // "description": "",
        // "recruiter_price_c2c_from": '',
        // "recruiter_price_c2c_to": '',
        // "recruiter_price_w2_from": '',
        // "recruiter_price_w2_to": '',
        // 'notes': ''
    }
    )
    const [Visatype, setVisatype] = useState({})
    const [Jobtype, setJobtype] = useState({})
    const [HeadRecruiter, setHeadRecruiter] = useState({})
    const [Requirement, setRequirement] = useState({})
    const [Requirement_status, setRequirement_status] = useState({})

    const [req_noteslist, setReq_noteslist] = useState([])

    const { id } = useParams()

    const Requirement_id = id


    const [RecruiterList, setRecruiterList] = useState([])
    const [CandidateList, setCandidateList] = useState(null)

    const [query, setQuery] = useState({
        per_page: 10,
        page: 1,
        search: '',
        sort_by: "",
        sort_direction: '',
        typing: "",


    });

    useEffect(() => {


        getAll(query);
    }, []);
    const getAll = async (query) => {
        const token = localStorage.getItem("IDMS_TOKEN");


        var data = {
            "method": "get",
            "route_name": "requirements/" + Requirement_id + "?",
            "query": query
        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response.success.........", Response.success)
            setFillabledata(Response.success.requirement)
            setVisatype(Response.success.visa)
            setJobtype(Response.success.job_type)
            setHeadRecruiter(Response.success.head_recruiter)
            setReq_noteslist(Response.success.requirement_notes)
            // setRequirement_status(Response.success.head_recruiter.requirement.status)
            setRequirement(Response.success.head_recruiter.requirement)
            setRecruiterList(Response.success.recruiters)
            setCandidateList(Response.success.candidates)


        }
        else {
            console.log("Error", Response.error)

        }

        // try {
        //     const Response = await axios({
        //         method: "get",
        //         url: `${REQUIREMENT_URL}/` + Requirement_id,
        //         headers: {
        //             Authorization: token
        //         },

        //     })

        //     console.log("Response====>", Response.data)
        //     setFillabledata(Response.data)
        //     setVisatype(Response.data.visa)
        //     setJobtype(Response.data.job_type)
        //     setHeadRecruiter(Response.data.HeadRecruiter)
        //     setReq_noteslist(Response.data.requirement_notes)


        // }

        // catch (err) {
        //     console.log("errr====>", err)
        // }

    };


    const DeleteFunc = async (id) => {


        var data = {
            "method": "DELETE",
            "route_name": "requirements/" + Requirement_id + "/requirement_notes/" + id,

        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response.success====>", Response.success)

            getAll()
            swal("Success!", "Notes Deleted Successfully!", "success")

            setTimeout(() => {
                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error)

        }




    };


    const PdfDownload = async (id) => {
        var data = {
            "method": "GET",
            "route_name": "candidates/" + id + '/files/resume',
            "res_type": 'blob'
        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response", Response.success)
            saveAs(Response.success, 'downloaded_resume.pdf');


        }
        else {
            console.log("Error", Response.error)

        }


    }

    const PageOnpresFunc = (pagedrop, value, type) => {


        console.log("type", type)

        var post_data = {
            per_page: pagedrop,
            page: value,
            search: query.search,
            sort_by: "",
            sort_direction: '',
            typing: type,


        }
        console.log("value", value)

        setQuery(post_data)

        getAll(post_data);

    }


    const OnSearchFunc = (value, type) => {


        var post_data = {
            per_page: query.per_page,
            page: 1,
            search: value,
            sort_by: "",
            sort_direction: '',
            typing: type,
        }
        console.log("value", value)
        setQuery(post_data)
        getAll(post_data);

    }


    return (

        <div className="d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}



                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Requirement Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">View Assigned Requirement</h6>
                                    <div>
                                        <Link type="button" className=" btn btn-default Organ_addbtn" to="/candidates_add" ><i className="fa fa-plus-circle arrowkey"></i>Add Candidate</Link>
                                        <Link type="button" className=" btn btn-default Organ_addbtn" to="/recruiter_requirements" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>
                                    </div>


                                </header>
                                <div className="row Organization_sub">
                                    <div className="col-sm-12 col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Requirement No.</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.requirement_no}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Requirement Title</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.title}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Job Type</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.job_type}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Job Location</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.location}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Recruiter Rate (1099)</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                $ {fillabledata.recruiter_price_c2c_from} Per Hour -  $ {fillabledata.recruiter_price_c2c_to} Per Hour
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Recruiter Rate (W2)</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                $ {fillabledata.recruiter_price_w2_from} Per Hour -  $ {fillabledata.recruiter_price_w2_to} Per Hour
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Description</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.description}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Note</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.notes}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-6">

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Visa Restrictions</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.visa_name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Local Candidate Only</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.is_local_candidate_only == 0 ? "NO" : "YES"}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Contract Length</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.contract_length}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">No of Positions</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.position}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Client Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.client_name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Created on</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                {moment(fillabledata.created_at).format('MM-DD-YYYY hh:mm A')}

                                            </div>
                                        </div>



                                    </div>

                                </div>




                            </section>

                        </div><br></br>


                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Head Recruiter Detail</h6>




                                </header>
                                <div className="row Organization_sub">
                                    <div className="col-sm-12 col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {HeadRecruiter.name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Email</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {HeadRecruiter.email}
                                            </div>
                                        </div>
                                        {/* <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Head Recruiter Status</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {Requirement_status.name}
                                            </div>
                                        </div> */}


                                    </div>

                                    <div className="col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Contact No.</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {HeadRecruiter.mobile ? HeadRecruiter.mobile
                                                    :
                                                    <span >  -</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Assigned On</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                {moment(HeadRecruiter.requirement_assigned_at).format('MM-DD-YYYY hh:mm A')}

                                            </div>
                                        </div>


                                    </div>

                                </div>




                            </section>

                        </div>

                        {CandidateList?.data.length > 0 || query.search ?
                            <div className="col-lg-12 ">
                                <section className="panel Orpage_box">

                                    <header class="Orvalidation_form">
                                        <h6 className="list_organ">List of Candidate</h6>

                                    </header>

                                    <Search_PageDrop OnSearch={(value) => OnSearchFunc(value, "candidate")} PageDropClick={(value) => PageOnpresFunc(value, 1, "candidate")} />






                                    <div className="table-colorOrgan mob-overflow">
                                        <table >
                                            <thead >

                                                <tr>
                                                    <th className="color">S.No</th>
                                                    <th className="color"><i className="fas fa-sort padding_sort"></i>Name</th>
                                                    <th className="color"><i className="fas fa-sort padding_sort"></i>Requirement No.</th>
                                                    <th className="color"><i className="fas fa-sort padding_sort"></i>Contact No.</th>
                                                    <th className="color"><i className="fas fa-sort padding_sort"></i>Qualification</th>
                                                    <th className="color"><i className="fas fa-sort padding_sort"></i>Visa Restriction</th>
                                                    <th className="color"><i className="fas fa-sort padding_sort"></i>Resume</th>
                                                    <th className="color"><i className="fas fa-sort padding_sort"></i>Status</th>
                                                    <th className="color"><i className="fas fa-sort padding_sort"></i>Selected On</th>
                                                    <th className="color">Action</th>

                                                </tr>
                                            </thead>
                                            {CandidateList?.data.length > 0 ?
                                                <tbody>
                                                    {CandidateList?.data.map((e, i) => (
                                                        < tr className="tabledata">
                                                            <td>{i + 1}</td>
                                                            <td style={{ color: 'blue' }}>{e.name} </td>
                                                            <td>{e.requirement.requirement_no}</td>
                                                            <td>{e.mobile}</td>
                                                            <td>{e.qualification}</td>
                                                            <td>{e.visa_name}</td>
                                                            <td >
                                                                <Link onClick={() => PdfDownload(e.id)}>
                                                                    <i className="fas fa-download"> Download</i>
                                                                </Link>
                                                            </td>
                                                            <td>{e.requirement_status}</td>
                                                            <td>
                                                                {moment(e.created_at).format('MM-DD-YYYY hh:mm A')}

                                                            </td>
                                                            <td>
                                                                <div className="dropdown history_dropdown">
                                                                    <button className="dropdown_alert">
                                                                        <span className="">▼</span>
                                                                    </button>
                                                                    <div className="dropdown-content">
                                                                        <Link to={`/candidates_view/${e.id}`}  > View</Link>


                                                                    </div>
                                                                </div>
                                                            </td>


                                                        </tr>
                                                    ))}
                                                </tbody>
                                                :
                                                <tbody>

                                                    <tr style={{ textAlign: 'center' }}>
                                                        <td colSpan="10" style={{ textAlign: 'center', verticalAlign: 'middle' }}>No Record Found</td>
                                                    </tr>
                                                </tbody>
                                            }
                                        </table>
                                    </div>

                                    <PaginationContent content={CandidateList} PageClick={(value) => PageOnpresFunc(query.per_page, value, "candidate")} />



                                </section>

                            </div>


                            : null}










                        {req_noteslist.length > 0 ?

                            <div className="col-lg-12 ">
                                <section className="panel Orpage_box">

                                    <header class="Orvalidation_form">
                                        <h6 className="list_organ">View Requirement Notes</h6>

                                    </header>

                                    <div className="notes_scroll">

                                        {req_noteslist.map((e, i) => (



                                            <div className="req_notesview row" >

                                                <div className="col-sm-1" style={{ fontWeight: '300' }}>
                                                    {i + 1}.
                                                </div>
                                                <div className="col-sm-9" >

                                                    <div style={{ fontWeight: '300', fontStyle: "initial" }} >
                                                        {e.body}
                                                    </div>
                                                    <div style={{ fontWeight: '300', fontStyle: "italic", marginTop: 8 }} >
                                                        {e.created_by.name} - {e.created_by.user_type}
                                                    </div>

                                                </div>

                                                <div className="col-sm-2 reqnote_time" >
                                                    2023-11-22 9:41 AM
                                                </div>
                                                {e.is_can_delete ?
                                                    <div onClick={() => DeleteFunc(e.id)} className="close-icon-container">
                                                        <img alt="panelImage" src="../../img/pane/cross.png" className='logo_notes' />
                                                    </div>
                                                    : null}
                                            </div>




                                        ))
                                        }
                                    </div>
                                </section>
                            </div>
                            : null}




                    </div>
                </div>
            </div >
        </div >





    );
}
