import React, { useEffect, useState } from "react";
import { CDBBtn, CDBIframe, CDBView } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { STAFF_URL } from "../../../constant";
import axios from "axios";
import { Link } from 'react-router-dom';
import swal from "sweetalert";
import Select from 'react-select';
export const StaffEdit = () => {

    const navigate = useHistory()
    const [StaffVal, setStafftVal] = useState('');
    const [StaffVal1, setStafftVal1] = useState('');
    const [StaffVal2, setStafftVal2] = useState('');
    const [StaffVal3, setStafftVal3] = useState('');

    const [head_recruiter_id, setHead_recruiter_id] = useState('');


    const [HeadRecruiter, setHeadRecruiter] = useState([]);
    const [error, setError] = useState({});

    const location = useLocation();


    const { id } = useParams()

    const staff_id = id

    useEffect(() => {


        fetchData();
    }, []);



    const fetchData = async () => {
        const token = localStorage.getItem("IDMS_TOKEN");


        try {
            const Response = await axios({
                method: "GET",
                url: `${STAFF_URL}/` + staff_id + "/edit",
                headers: {
                    Authorization: token
                }
            })

            console.log("Response====>", Response.data)
            setStafftVal(Response.data.staff.first_name)
            setStafftVal1(Response.data.staff.last_name)
            setStafftVal2(Response.data.staff.email)
            setStafftVal3(Response.data.staff.user_type)
            setHead_recruiter_id(Response.data.staff.headrecuiter_id)


            if (Response.data.head_recruiters) {
                const array1 = Response.data.head_recruiters.map((e) => ({

                    label: e.name,
                    value: e.id

                }))


                setHeadRecruiter(array1)
            }

        }

        catch (err) {
            console.log("errr====>", err.response.data.errors)
        }

    };


    const onchangefun2 = (e) => {


        console.log("e.target.value", e)
        setHead_recruiter_id(e.value)


    };

    const handleStaff = (event) => {

        setStafftVal(event.target.value);

        // console.log(event);


    };
    const handleStaff1 = (event) => {

        setStafftVal1(event.target.value);
    };
    const handleStaff2 = (event) => {

        setStafftVal2(event.target.value);
    };
    const handleStaff3 = (event) => {

        setStafftVal3(event.target.value);
    };

    const handleSubmit = async () => {



        const token = localStorage.getItem("IDMS_TOKEN")

        console.log("token====>", token)


        var body = {
            "first_name": StaffVal,
            "last_name": StaffVal1,
            "email": StaffVal2,
            "user_type_id": StaffVal3,
            // "_method": "",

        }


        try {
            const Response = await axios({
                method: "PUT",
                url: `${STAFF_URL}/` + staff_id,
                data: body,
                headers: {
                    Authorization: token
                }
            })


            console.log("Response====>", Response.data)

            swal("Success!", "Staff Updated Successfully!", "success")
            setTimeout(() => {
                navigate.push("/staffs")

                swal.close();
            }, 1500);

        }

        catch (err) {
            console.log("errr====>", err.response.data.errors)
            setError(err.response.data.errors)
        }


    }




    return (
        <div className="d-flex profile">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>


                        {/*  */}

                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Staff Management</h4>
                        </div>



                        <div className="col-lg-12 ">

                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Edit Staff</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" to="/staffs" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>

                                </header>



                                <div className="row Organization_sub">

                                    <div className="col-sm-12 col-md-6">




                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-3 control-label">First Name <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control  " placeholder="" value={StaffVal}
                                                    onChange={handleStaff} />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.first_name}</span>
                                            </div>
                                        </div>


                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-3 control-label">Last Name <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control  " placeholder="" value={StaffVal1}
                                                    onChange={handleStaff1} />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.last_name}</span>
                                            </div>
                                        </div>



                                    </div>

                                    <div className="col-md-6">


                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-3 control-label">Email ID <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control" value={StaffVal2}
                                                    onChange={handleStaff2} />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.email}</span>
                                            </div>
                                        </div>
                                        <div className="form-group form-textSetting" >
                                            <label for="" className="col-sm-3 control-label">User Type</label>
                                            <div className="col-sm-7" style={{ margintop: '5px' }} >
                                                <label onChange={handleStaff3} >{StaffVal3.type}</label>
                                            </div>
                                        </div>



                                        {StaffVal3.id == 5 ?
                                            < div className="form-group form-textSetting">
                                                <label for="inputPassword3" className="col-sm-3 control-label">Head Recruiter <span className="required">*</span></label>
                                                <div className="col-sm-7">


                                                    <div className="">
                                                        <Select

                                                            value={HeadRecruiter.find(option => option.value === head_recruiter_id)}
                                                            placeholder="[-Select Head Recruiter-]"
                                                            onChange={(e) => onchangefun2(e)}
                                                            options={HeadRecruiter}
                                                        />
                                                    </div>

                                                    <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.headrecuiter_id}</span>



                                                </div>
                                            </div>
                                            : null}










                                        <div className="form-group">

                                            <button type="button" onClick={() => handleSubmit()} className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>

                                        </div>





                                    </div>




                                </div>



                            </section>

                        </div>





                    </div>





                </div>
            </div>

        </div >






    );
}