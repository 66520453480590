import React, { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
import { CDBBtn, CDBIframe, CDBView, } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import "./OraganizationList.css"
import axios from "axios";
import { ORGANIZATION_URL } from "../../../constant";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Spinner } from "../../../Components/Spinner";
import { ApiCall } from "../../../Config/API";
import swal from "sweetalert";
import { Search_PageDrop } from "../../../Components/Search_PageDrop";
import { PaginationContent } from "../../../Components/PaginationContent";
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";



export const OraganizationList = () => {


	const navigate = useHistory()


	const [Data, setData] = useState([]);
	const [loader, setloader] = useState(false);
	const [query, setQuery] = useState({
		per_page: 10,
		page: 1,
		search: '',
		sort_by: "",
		sort_direction: ''

	});

	useEffect(() => {

		fetchData(query);

	}, []);

	const { dispatch } = useApiResponseContext();




	const fetchData = async (query) => {


		console.log("query", query)
		var data = {
			"method": "get",
			"route_name": `organizations?`,
			'query': query

		}
		const Response = await ApiCall(data)
		if (Response.success) {

			console.log("Response", Response.success)
			setloader(false)
			setData(Response.success.data)
			dispatch({ type: 'SUCCESS', payload: Response.success });

		}
		else {
			console.log("Error", Response.error.errors)
			dispatch({ type: 'ERROR', payload: Response.error });
		}

	};




	const EditFunc = async (idd) => {

		console.log("fsfsf", idd)

		// navigate.push(`/OrganizationEdit/${idd}`);

		navigate.push('/organization_edit', {
			state: {
				"id": idd,
			}
		});

	}

	const ViewFunc = async (idd) => {

		console.log("fsfsf", idd)

		// navigate.push(`/OrganizationEdit/${idd}`);

		navigate.push('/organization_view', {
			state: {
				"id": idd,
			}
		});

	}
	const statusChangeFunc = async (org_id, index) => {


		var data = {
			"method": "PUT",
			"route_name": "organization_statuses/" + org_id

		}

		const Response = await ApiCall(data)

		if (Response.success) {

			console.log("Response.success====>", Response.success)


			var array = [...Data]
			array[index].status.name = (array[index].status.name != "Active" ? "Active" : 'In active')
			setData(array)


			swal("Success!", "Organization Status updated successfully!", "success")

			setTimeout(() => {
				swal.close();
			}, 1500);

		}
		else {
			console.log("Error", Response.error)

		}


	}


	const PageOnpresFunc = (pagedrop, value,) => {

		var post_data = {
			per_page: pagedrop,
			page: value,
			search: query.search,
			sort_by: "",
			sort_direction: ''
		}
		console.log("value", value)

		setQuery(post_data)

		fetchData(post_data);

	}


	const OnSearchFunc = (value) => {


		var post_data = {
			per_page: query.per_page,
			page: 1,
			search: value,
			sort_by: "",
			sort_direction: ''
		}
		console.log("value", value)
		setQuery(post_data)
		fetchData(post_data);

	}
	return (
		<div>
			<div className="d-flex profile">

				<div>
					
				</div>
				<div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
					
					{!loader ?
						<div style={{ height: "100%" }}>
							<div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>



								<div className="contanier-fluid">
									<h4 className="profile-tilte">Organization Management</h4>
								</div>

								<div className="col-lg-12 ">
									<section className="panel Orpage_box">

										<header class="Orvalidation_form">
											<h6 className="list_organ">List of Organization</h6>
											<Link type="button" className=" btn btn-default Organ_addbtn" to="/organizations_add" ><i className="fa fa-plus-circle arrowkey"></i>Add Organization</Link>



										</header>


										<Search_PageDrop OnSearch={(value) => OnSearchFunc(value)} PageDropClick={(value) => PageOnpresFunc(value, 1)} />





										<div className="table-colorOrgan mob-overflow" >
											<table>
												<thead >

													<tr >
														<th className="color">S.No</th>
														<th className="color"><i className="fas fa-sort padding_sort"></i>Organization Name</th>
														<th className="color"><i className="fas fa-sort padding_sort"></i>Email</th>
														<th className="color"><i className="fas fa-sort padding_sort"></i>Mobile</th>
														<th className="color"><i className="fas fa-sort padding_sort"></i>Status</th>
														<th className="color"><i className="fas fa-sort padding_sort"></i>Current Status</th>
														<th className="color"><i className="fas fa-sort padding_sort"></i>Action</th>
													</tr>
												</thead>
												{Data.length > 0 ?
													<tbody>
														{Data.map((organization, index) => (
															<tr key={index} className="tabledata">
																<td>{((query.page - 1) * 10 + 1) + index}</td>

																{/* <td>{organization.id}</td> */}
																<td>{organization.company_name}</td>
																<td>{organization.email}</td>
																<td>{organization.mobile}</td>
																<td>
																	<span
																		className={
																			organization.status.name === 'Active'
																				? 'green-status'
																				: organization.status.name === 'In active'
																				&& 'red-status'

																		}
																	>
																		{organization.status.name}
																	</span>
																</td>

																<td>

																	<Link onClick={() => statusChangeFunc(organization.id, index)}  >{organization.status.name === 'Active' ? "Click to Deactivate" : "Click to Activate"}</Link>


																	{/* (
																): organization.status.name === 'In active' && (
															 	<Link href="">Click to Activate</Link>
																 )} */}
																</td>
																<td>
																	<div className="dropdown history_dropdown">
																		<button className="dropdown_alert">
																			<span className="">▼</span>
																		</button>
																		<div className="dropdown-content">

																			{/* <a onClick={() => EditFunc(organization.id)}> Edit</a>

																		<a onClick={() => ViewFunc(organization.id)}> View</a> */}
																			<Link to={`/organization_view/${organization.id}`}  > View</Link>
																			<Link to={`/organization_edit/${organization.id}`}  > Edit</Link>


																		</div>
																	</div>
																</td>
															</tr>

														))}
													</tbody>
													:
													<tbody>

														<tr style={{ textAlign: 'center' }}>
															<td colSpan="10" style={{ textAlign: 'center', verticalAlign: 'middle' }}>No Record Found</td>
														</tr>
													</tbody>
												}
											</table>
										</div>

										<PaginationContent PageClick={(value) => PageOnpresFunc(query.per_page, value)} />


									</section>

								</div>


							</div>



						</div>

						:

						<Spinner />
					}
				</div>




			</div >


		</div>
	);
}