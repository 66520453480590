import React, { useEffect, useState } from "react";
import { InfinitySpin, MutatingDots, RotatingLines } from "react-loader-spinner";





export const TableHeader = (props,) => {



    const [Array, setArray] = useState([])


    useEffect(() => {

        setArray(props.Titles)
    }, [])
    const OnchangeFunc = (key, direct, index, sort) => {


        // console.log("key", key)
        // console.log("direct", direct)
        var data = [...Array]

        // if (sort) {
        //     data[0].sort = "desc"
        //     if (direct == 'asc') {
        //         data[index].sort = 'desc'
        //     }
        //     else {
        //         data[index].sort = 'asc'
        //     }
        // }
        // else {
        data[index].sort = direct
        // 
        // }

        console.log("data---->", data)
        setArray(data)
        props.Sortfunc(key, direct, data)

        // const updatedArray = updateObjectKey(2, "key", "new_value");

        // console.log(updatedArray);

    }


    
    // function updateObjectKey(objectIndex, keyToUpdate, newValue) {
    //     const newArray = TableHeadings.map((obj, index) => {
    //         if (index === objectIndex) {
    //             // Update the specified key for the target object
    //             return { ...obj, [keyToUpdate]: newValue };
    //         }
    //         return obj;
    //     });

    //     return newArray;
    // }

    // Example: Update the "key" property of the object at index 2
 


    return (
        <thead >
            <tr>
                {Array.map((e, i) => (
                    <th className="color">{e.name}
                        {e.icon ? (

                            e.sort == "desc" ?

                                <i onClick={() => OnchangeFunc(e.key && e.key, "asc", i)} className={e.key ? "fas fa-sort-down padding_sort" : "fas fa-sort-up padding_sort"}></i>

                                : e.sort == "asc" ?
                                    <i onClick={() => OnchangeFunc(e.key && e.key, "desc", i)} className={e.key ? "fas fa-sort-up padding_sort" : "fas fa-sort-down padding_sort"}></i>
                                    :
                                    e.key &&
                                    <i onClick={() => OnchangeFunc(e.key && e.key, 'asc', i, "sort")} className="fas fa-sort padding_sort"></i>

                        )
                            : null}
                    </th>
                ))
                }
            </tr>
        </thead>

    )




}

