
import key from './';


console.log("key", key.BASE_API)



export const URL_Export = () => {

    const Type = localStorage.getItem("user_type")
    console.log("Typess", Type)
    var user_type = '/'

    if (Type == "Super admin") {

        user_type = "/super_admins/"

    }
    else if (Type == "Admin") {

        user_type = "/admins/"

    }
    else if (Type == "Account manager") {

        user_type = "/account_managers/"

    }
    else if (Type == "Head recuiter") {

        user_type = "/head_recruiters/"

    }
    else if (Type == "Recuiter") {

        user_type = "/recruiters/"

    }

    const BASE_URL = key.BASE_API + user_type

    return BASE_URL

}
// export const BASE_URL = key.BASE_API + user_type
