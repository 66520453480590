import React, { useEffect, useState } from "react";
import { CDBBtn, CDBIframe, CDBView } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { JOBTYPE_URL } from "../../../constant";
import { Link } from 'react-router-dom';
import swal from "sweetalert";
import { ApiCall } from "../../../Config/API";
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";
import { PaginationContent } from "../../../Components/PaginationContent";
import { Search_PageDrop } from "../../../Components/Search_PageDrop";


export const JobType_List = () => {



	// const [query, setQuery] = useState('');


	// const handleSearch = async () => {

	// 	try {
	// 		const Response = await axios({
	// 			method: "get",
	// 			url: `https://api.idms.ifinitesolutions.com/api/job_types?search=` + query,

	// 		});

	// 		console.log("test", Response.data)

	// 		setData2(Response.data.data);


	// 	}
	// 	catch (err) {
	// 		console.log("erroor==<", err.response.data.errors);


	// 	}

	// };

	const navigate = useHistory()


	const [data2, setData2] = useState([]);

	const [query, setQuery] = useState({
		per_page: 10,
		page: 1,
		search: '',
		sort_by: "",
		sort_direction: ''

	});

	useEffect(() => {

		fetchData4(query);

	}, []);

	const { dispatch } = useApiResponseContext();

	const fetchData4 = async (query) => {


		console.log("query", query)
		var data = {
			"method": "get",
			"route_name": `job_types?`,
			'query': query

		}
		const Response = await ApiCall(data)
		if (Response.success) {

			console.log("Response", Response.success)
			setData2(Response.success.data)



			dispatch({ type: 'SUCCESS', payload: Response.success });

		}
		else {
			console.log("Error", Response.error.errors)
			dispatch({ type: 'ERROR', payload: Response.error });
		}

	};




	const EditFunc = async (idd) => {

		console.log("fsfsf", idd)

		// navigate.push(`/OrganizationEdit/${idd}`);

		navigate.push('/jobtype_edit', {
			state: {
				"id": idd,
			}
		});

	}

	const statusChangeFunc = async (jobtype_id, index) => {


		var data = {
			"method": "PUT",
			"route_name": "job_statuses/" + jobtype_id

		}

		const Response = await ApiCall(data)

		if (Response.success) {

			console.log("Response.success====>", Response.success)


			var array = [...data2]
			array[index].status.name = (array[index].status.name != "Active" ? "Active" : 'In active')
			setData2(array)


			swal("Success!", "Job type Status updated successfully!", "success")

			setTimeout(() => {
				swal.close();
			}, 1500);

		}
		else {
			console.log("Error", Response.error)

		}
	}
	const PageOnpresFunc = (pagedrop, value,) => {

		var post_data = {
			per_page: pagedrop,
			page: value,
			search: query.search,
			sort_by: "",
			sort_direction: ''
		}
		console.log("value", value)

		setQuery(post_data)

		fetchData4(post_data);

	}


	const OnSearchFunc = (value) => {


		var post_data = {
			per_page: query.per_page,
			page: 1,
			search: value,
			sort_by: "",
			sort_direction: ''
		}
		console.log("value", value)
		setQuery(post_data)
		fetchData4(post_data);

	}
	return (
		<div className="d-flex profile">
			<div>
				
			</div>
			<div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
				
				<div style={{ height: "100%" }}>
					<div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>


						{/*  */}





						<div className="contanier-fluid">
							<h4 className="profile-tilte">Job Type Management</h4>
						</div>

						<div className="col-lg-12 ">
							<section className="panel Orpage_box">

								<header class="Orvalidation_form">
									<h6 className="list_organ">List of Job Type</h6>
									<Link type="button" className=" btn btn-default Organ_addbtn" to="/jobtype_add" ><i className="fa fa-plus-circle arrowkey"></i>Add Job Type</Link>



								</header>

								<Search_PageDrop OnSearch={(value) => OnSearchFunc(value)} PageDropClick={(value) => PageOnpresFunc(value, 1)} />






								<div className="table-colorOrgan mob-overflow" >
									<table>
										<thead >

											<tr >
												<th className="color">S.No</th>
												<th className="color"><i className="fas fa-sort padding_sort"></i>Name</th>

												<th className="color"><i className="fas fa-sort padding_sort"></i>Status</th>
												<th className="color"><i className="fas fa-sort padding_sort"></i>Current Status</th>
												<th className="color"><i className="fas fa-sort padding_sort"></i>Action</th>
											</tr>
										</thead>

										<tbody>


											{data2.map((Job, index) => (
												<tr key={index} className="tabledata">
													<td>{((query.page - 1) * 10 + 1) + index}</td>
													<td>{Job.type}</td>

													<td>
														<span
															className={
																Job.status.name === 'Active'
																	? 'green-status'
																	:
																	'red-status'

															}
														>
															{Job.status.name}
														</span>
													</td>

													<td>
														{/* {Job.status.name === 'Active' ? (
															<a href="">Click to Deactivate</a>
														) : Job.status.name === 'In active' ? (
															<a href="">Click to Activate</a>
														) : Job.status.name === 'Pending' ? (
															<a href="">Click to Process</a>
														) : (
															<a href="">Click to Something Else</a>
														)} */}


														<Link onClick={() => statusChangeFunc(Job.id, index)}  >{Job.status.name === 'Active' ? "Click to Deactivate" : "Click to Activate"}</Link>

													</td>
													<td>
														<div className="dropdown history_dropdown">
															<button className="dropdown_alert">
																<span className="">▼</span>
															</button>
															<div className="dropdown-content">

																{/* <a onClick={() => EditFunc(Job.id)}> Edit</a> */}
																<Link to={`/jobtype_edit/${Job.id}`}  >Edit</Link>


															</div>
														</div>
													</td>
												</tr>

											))}


										</tbody>
									</table>
								</div>

								<PaginationContent PageClick={(value) => PageOnpresFunc(query.per_page, value)} />



							</section>

						</div>




					</div>





				</div>
			</div>

		</div >






	);
}