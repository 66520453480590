import React, { useState } from "react";
import "./Dashboard.css";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import { ApiCall } from "../../Config/API";
import swal from "sweetalert";

export const ChangePassword = () => {



    const [error, setError] = useState({});


    const [candidatedetails, setCandidatedetails] = useState({
        current_password: "",
        new_password: "",
        new_password_confirmation: ''

    })

    const ChangePasswordFunc = async () => {


        var data = {
            "method": "POST",
            "route_name": "/change_password",
            "body": candidatedetails
        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response", Response.success)
            swal("Success!", "Password Changed Successfully.!", "success")
            setError({})
            setCandidatedetails({
                current_password: "",
                new_password: "",
                new_password_confirmation: ''

            })
            setTimeout(() => {
                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error.errors)
            setError(Response.error.errors)
        }
    }

    const onChangeFunc = (fieldName, e) => {
        setCandidatedetails((prevData) => ({
            ...prevData,
            [fieldName]: e,
        }));
    }



    return (
        <div className="dashboard d-flex">
            <div>
                {/*  */}
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                {/*  */}
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>




                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Change Password</h4>
                        </div>

                        <div className="container-fluid">
                            <div className="row borderbox">
                                <h4 className="Editprofile">Change Password</h4>
                                <div className="col-sm-12 col-md-12 changepas-center" style={{ marginTop: '10px' }}>



                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-6   control-label input-placeholder">Current Password <span className="required"> *</span></label>
                                        <div className="col-sm-11">
                                            <input
                                                onChange={(e) => onChangeFunc('current_password', e.target.value)}
                                                type="password" className="form-control validate[required,custom[phone]]" placeholder="Current password" />

                                            {error.current_password ?
                                                <span style={{ marginTop: 10 }} className="span-error" >{error.current_password}</span>
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-6  control-label input-placeholder">New Password<span className="required"> *</span></label>
                                        <div className="col-sm-11">
                                            <input
                                                onChange={(e) => onChangeFunc('new_password', e.target.value)}

                                                type="password" className="form-control validate[required,custom[phone]]" placeholder="New Password" />
                                            {error.new_password ?
                                                <span style={{ marginTop: 10 }} className="span-error" >{error.new_password}</span>
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-6  control-label input-placeholder">Confirm Password<span className="required"> *</span></label>
                                        <div className="col-sm-11">
                                            <input
                                                onChange={(e) => onChangeFunc('new_password_confirmation', e.target.value)}

                                                type="password" className="form-control validate[required,custom[phone]]" placeholder="Confirm Password" />
                                            {error.new_password_confirmation ?
                                                <span style={{ marginTop: 10 }} className="span-error" >{error.new_password_confirmation}</span>
                                                : null}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className=" col-sm-12">
                                            <button onClick={() => ChangePasswordFunc()} type="button" className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>
                                        </div>
                                    </div>

                                </div>

                            </div>






                        </div>



                        <div className="container-fluid background-color" >

                        </div>


























                    </div>
                </div>
            </div>







        </div >

    );
}