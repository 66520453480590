import React, { createContext, useReducer, useContext } from 'react';

// Define the reducer function
const apiReducer = (state, action) => {
  switch (action.type) {
    case 'SUCCESS':
      return { success: action.payload, error: null, };
    case 'ERROR':
      return { success: null, error: action.payload };
    case 'START_LOADING':
      return { ...state, loading: true };
    case 'STOP_LOADING':
      return { ...state, loading: false };
    case 'PROFILE':
      return { profile_data: action.payload };
    default:
      return state;
  }
};

const ApiResponseContext = createContext();

export const useApiResponseContext = () => {
  return useContext(ApiResponseContext);
};

export const ApiProvider = ({ children }) => {
  const [apiState, dispatch] = useReducer(apiReducer, {
    success: null,
    error: null,
    profile_data: null

  });


  const startLoading = () => {
    dispatch({ type: 'START_LOADING' });
  };

  const stopLoading = () => {
    // You can add additional logic here if needed
    dispatch({ type: 'STOP_LOADING' });
  };

  const contextValue = {
    apiState,
    // startLoading,
    // stopLoading,
    dispatch
  };


  return (
    <ApiResponseContext.Provider value={contextValue}>
      {children}
    </ApiResponseContext.Provider>
  );
};
