import React, { useState } from "react";
import {
    CDBBtn,
    CDBProgress,
    CDBTable,
    CDBTableHeader,
    CDBTableBody,
    CDBContainer,
    CDBLink
} from "cdbreact";

import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import "./Dashboard.css";
import Select from 'react-select';

const Citoption = [

    { value: 'US Citizens Only', label: 'US Citizens Only' },
 
];
const Partoption = [

    { value: 'Robert', label: 'Robert' },
 
];
const Joboption = [

    { value: 'Full Time', label: 'Full Time' },
    { value: 'Part Time', label: 'Part Time' }
 
];
export const JobhistoryEdit = () => {

    const [Citizen, setCitizen] = useState(null);
    const [Partner, setPartner] = useState(null);
    const [Job, setJob] = useState(null);


    return (
        <div className="dashboard d-flex">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>


                        {/*  */}

                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Requirement Management</h4>
                        </div>

                        <div className="container-fluid">
                            <div className="row borderbox">
                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Edit Requirement</h6>
                                    <a type="button" className=" btn btn-default Organ_addbtn" href="/Profile" ><i className="fa fa-arrow-left arrowkey"></i>Back</a>

                                </header>


                                <div className="col-sm-12 col-lg-6" style={{ marginTop: '18px' }}>




                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Client Name <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" id="mobile"  name="data[User][mobile]" required />
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Partner Name<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <div className="">
                                                <Select
                                                    defaultValue={Partner}
                                                    onChange={setPartner}
                                                    options={Partoption}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Requirement<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" id="mobile"  name="data[User][mobile]" required />
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Job Location  <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]] " required />
                                        </div>
                                    </div>

                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Job Type<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <div className="">
                                                <Select
                                                    defaultValue={Job}
                                                    onChange={setJob}
                                                    options={Joboption}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Rate <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <form className="checkbox_visa">
                                                <div className="form-check" >
                                                    <input className="form-check-input" type="radio" name="radioOptions" id="radioOption1" value="option1" />
                                                    <label className="form-check-label" for="radioOption1">
                                                        Per Hour
                                                    </label>
                                                </div>
                                                <div className="form-check checkmtop">
                                                    <input className="form-check-input" type="radio" name="radioOptions" id="radioOption2" value="option2" />
                                                    <label className="form-check-label" for="radioOption2">
                                                        Per Month
                                                    </label>
                                                </div>
                                                <div className="form-check checkmtop">
                                                    <input className="form-check-input" type="radio" name="radioOptions" id="radioOption2" value="option2" />
                                                    <label className="form-check-label" for="radioOption2">
                                                        Per Year
                                                    </label>
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Partner Rate<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" id="mobile"  name="data[User][mobile]" required />
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Recruiter Rate (1099)<span className="required">*</span></label>
                                        <div className="col-sm-7" style={{ display: 'flex' }}>

                                            <div className="col-sm-5">
                                                <input type="text" className="form-control validate[required,custom[phone]]" id="mobile"  name="data[User][mobile]" required />
                                            </div>
                                            <div className="col-sm-1">
                                                <label>-</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input type="text" className="form-control validate[required,custom[phone]]" id="mobile"  name="data[User][mobile]" required />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Recruiter Rate (W2)<span className="required">*</span></label>
                                        <div className="col-sm-7" style={{ display: 'flex' }}>

                                            <div className="col-sm-5">
                                                <input type="text" className="form-control validate[required,custom[phone]]" id="mobile"  name="data[User][mobile]" required />
                                            </div>
                                            <div className="col-sm-1">
                                                <label>-</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input type="text" className="form-control validate[required,custom[phone]]" id="mobile"  name="data[User][mobile]" required />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Description<span className="required">*</span></label>
                                        <div className="col-sm-7">

                                        </div>
                                    </div>



                                </div>
                                <div className="col-md-6" style={{ marginTop: '18px' }}>

                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Visa Restriction<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <div className="">
                                                <Select
                                                    defaultValue={Citizen}
                                                    onChange={setCitizen}
                                                    options={Citoption}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Local Candidate Only <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <form className="checkbox_visa">
                                                <div className="form-check" >
                                                    <input className="form-check-input" type="radio" name="radioOptions" id="radioOption1" value="option1" />
                                                    <label className="form-check-label" for="radioOption1">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check checkmtop">
                                                    <input className="form-check-input" type="radio" name="radioOptions" id="radioOption2" value="option2" />
                                                    <label className="form-check-label" for="radioOption2">
                                                        No
                                                    </label>
                                                </div>

                                            </form>
                                        </div>

                                    </div>


                                    <div className="form-group form-textSetting">
                                        <label for="inputPassword3" className="col-sm-3 control-label">Contract Length <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control inputbox-height validate[required]" id="city" name="data[User][city]" />
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="inputPassword3" className="col-sm-3 control-label">No of Positions <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control inputbox-height validate[required]" id="state" name="data[User][state]" />
                                        </div>
                                    </div>








                                </div>


                                <div className="form-group form-textSetting">
                                    <label for="inputEmail3" class="col-sm-3 control-label">Notes <span class="required">*</span></label>
                                    <div className="col-sm-7">
                                        <textarea className="form-control inputbox-height validate[required]" id="address" cols="5" name="data[User][address]" ></textarea>
                                    </div>
                                </div>


                            </div>






                        </div>



                        <div className="container-fluid background-color" >

                        </div>


























                    </div>
                </div>
            </div>







        </div >

    );
}
