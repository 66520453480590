import React, { useEffect, useState } from "react";
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";
import { ApiCall } from "../../../Config/API";
import { Link } from "react-router-dom/cjs/react-router-dom";
import moment from "moment";
import { PaginationContent } from "../../../Components/PaginationContent";
import { Search_PageDrop } from "../../../Components/Search_PageDrop";
import { RequirementNoteModal } from "../../../Components/RequirementNoteModal";

export const Jobhistory_List = () => {



    const [query, setQuery] = useState({
        per_page: 10,
        page: 1,
        search: '',
        sort_by: "",
        sort_direction: 'desc'

    });


    useEffect(() => {

        getAll(query);

    }, []);

    const [jobhistorylist, setJobhistorylist] = useState([]);

    const [total_count, setTotal_count] = useState(0);
    const [notesmodal, setNotesmodal] = useState(false);

    const [requirement_id, setRequirement_id] = useState('');

    const { dispatch } = useApiResponseContext();

    const getAll = async (query) => {


        console.log("query", query)
        var data = {
            "method": "get",
            "route_name": `job_histories?`,
            'query': query

        }
        const Response = await ApiCall(data)
        if (Response.success) {

            console.log("Response", Response.success)
            setJobhistorylist(Response.success.data)

            setTotal_count(Response.success.total)

            dispatch({ type: 'SUCCESS', payload: Response.success });

        }
        else {
            console.log("Error", Response.error.errors)
            dispatch({ type: 'ERROR', payload: Response.error });
        }

    };

    const PageOnpresFunc = (pagedrop, value,) => {

        var post_data = {
            per_page: pagedrop,
            page: value,
            search: query.search,
            sort_by: "",
            sort_direction: query.sort_direction
        }
        console.log("value", value)

        setQuery(post_data)

        getAll(post_data);

    }


    const OnSearchFunc = (value) => {


        var post_data = {
            per_page: query.per_page,
            page: 1,
            search: value,
            sort_by: "",
            sort_direction: ''
        }
        console.log("value", value)
        setQuery(post_data)
        getAll(post_data);

    }

    const Functioncall = (i) => {

        var data = (((1 - 1) * 10 + 1) - 30)

        console.log("s.sort_by", query.sort_by)


    }


    const onSorting = (key, direct) => {


        var post_data = {
            per_page: query.per_page,
            page: query.page,
            search: '',
            sort_by: key ? key : '',
            sort_direction: direct
        }
        console.log("key", key)
        console.log("direct", direct)

        setQuery(post_data)
        getAll(post_data);



    }












    return (
        <div className="d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}


                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Job History</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Job History</h6>




                                </header>


                                <Search_PageDrop OnSearch={(value) => OnSearchFunc(value)} PageDropClick={(value) => PageOnpresFunc(value, 1)} />




                                <div className="table-colorOrgan mob-overflow">
                                    <table >
                                        <thead >
                                            <tr>
                                                <th className="color">S.No<i className="fas fa-sort padding_sort"></i></th>
                                                <th className="color">Requirement No.</th>
                                                <th className="color">Account Manager</th>
                                                <th className="color">No.Candidates</th>
                                                <th className="color">Created On</th>
                                                <th className="color">Action</th>
                                                <th className="color">Notes</th>
                                            </tr>
                                        </thead>
                                        {jobhistorylist.length > 0 ?
                                            <tbody>
                                                {jobhistorylist.map((e, i) => (
                                                    <tr className="tabledata">
                                                        {query.sort_direction == "asc" && query.sort_by == "" ?
                                                            <td>{((total_count) - i) - ((query.page - 1) * 10)}</td>
                                                            :
                                                            <td>{((query.page - 1) * 10 + 1) + i}</td>
                                                        }
                                                        <td style={{ color: 'blue' }}>{e.requirement_no}</td>
                                                        <td>{e.account_manager}</td>
                                                        <td >({e.candidates_count})</td>
                                                        <td>{moment(e.created_at).format('MM-DD-YYYY hh:mm A')}</td>

                                                        <div className="dropdown history_dropdown">
                                                            <button className="dropdown_alert">
                                                                <span className="">▼</span>
                                                            </button>
                                                            <div className="dropdown-content">
                                                                <Link to={`/jobhistory_view/${e.id}`}  > View</Link>
                                                                <Link to={`/jobhistory_edit/${e.id}`}  > Edit</Link>


                                                            </div>
                                                        </div>
                                                        <td>
                                                            <Link onClick={() => (setNotesmodal(true), setRequirement_id(e.id))}>
                                                                <img alt="panelImage" src="img/pane/notes.jpg" className='logoimgnav2' />
                                                            </Link>

                                                        </td>

                                                    </tr>
                                                ))}

                                            </tbody>

                                            :
                                            <tbody>

                                                <tr style={{ textAlign: 'center' }}>
                                                    <td colSpan="10" style={{ textAlign: 'center', verticalAlign: 'middle' }}>No Record Found</td>
                                                </tr>
                                            </tbody>
                                        }
                                    </table>
                                </div>

                                <PaginationContent PageClick={(value) => PageOnpresFunc(query.per_page, value)} />


                            </section>

                        </div>






                        {notesmodal ?
                            <RequirementNoteModal req_id={requirement_id} closemodal={(value) => value && setNotesmodal(false)} />
                            : null}














                    </div>
                </div>
            </div>
        </div>










    );
}

