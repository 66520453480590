

import React from "react";
import {
  CDBSidebar,
  CDBSidebarContent,

  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem
} from "cdbreact";
import { NavLink } from "react-router-dom";
// import { Image } from "react-router-dom";
import "./Sidebar.css"
import './pages/allstyle.css'


const Sidebar = () => {

  const type = localStorage.getItem("user_type")
  const logo = localStorage.getItem("Logo")


  return (
    <div
      className={`app`}
      style={{ display: "flex", height: "100%", overflow: "scroll initial" }}
    >
      <CDBSidebar
        textColor="#fff"
        backgroundColor="#162031"
      >
        <CDBSidebarHeader
          prefix={
            <i className="fa fa-bars fa-large"></i>
          }
        >
          <a href="/" className="text-decoration-none" style={{ color: "inherit" }}>

            {type == "Super admin" ?
              <img src="img/pane/loogoo.png" className="left-logo" />
              :
              <img alt="panelImage" src={logo} className="left-logo" />
            }
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">

          <CDBSidebarMenu>
            <NavLink
              exact
              to="/profilesettings"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem icon="fas fa-cogs" className='fourheading' >
                Setting
              </CDBSidebarMenuItem>

            </NavLink>


          </CDBSidebarMenu>
          {type == "Super admin" ?
            <CDBSidebarMenu>



              <NavLink
                exact
                to="/emailcontents"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="far fa-envelope" className='fourheading'>
                  Email Mgmt
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/organizations"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fas fa-sitemap" className='fourheading'>
                  Organization Mgmt
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/user"

                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fas fa-user-cog" className='fourheading'>
                  Admin Mgmt
                </CDBSidebarMenuItem>
              </NavLink>


            </CDBSidebarMenu>
            : null}
          {type == "Admin" ?
            <CDBSidebarMenu>



              <NavLink
                exact
                to="/staffs"

                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fas fa-users" className='fourheading'>
                  Staff Mgmt
                </CDBSidebarMenuItem>
              </NavLink>

              <NavLink
                exact
                to="/jobtype"

                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fas fa-briefcase" className='fourheading'>
                  Job Type Mgmt
                </CDBSidebarMenuItem>
              </NavLink>


              <NavLink
                exact
                to="/visatype"

                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fas fa-plane" className='fourheading'>
                  Visa Type Mgmt
                </CDBSidebarMenuItem>
              </NavLink>

              <NavLink
                exact
                to="/jobhistory"

                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="far fa-clock" className='fourheading'>
                  Job History
                </CDBSidebarMenuItem>
              </NavLink>

              <NavLink
                exact
                to="/candidates"

                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fas fa-users" className='fourheading'>
                  Candidate History
                </CDBSidebarMenuItem>
              </NavLink>


            </CDBSidebarMenu>
            : null}
          {type == "Account manager" ?
            <CDBSidebarMenu>



              <NavLink
                exact
                to="/partners"

                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fas fa-user" className='fourheading'>
                  Partner Mgmt
                </CDBSidebarMenuItem>
              </NavLink>

              <NavLink
                exact
                to="/requirements"

                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fas fa-briefcase" className='fourheading'>
                  Requirement Mgmt
                </CDBSidebarMenuItem>
              </NavLink>

              <NavLink
                exact
                to="/shortlists"

                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fas fa-check-circle mr-2" className='fourheading'>
                  Short List Mgmt
                </CDBSidebarMenuItem>
              </NavLink>

              <NavLink
                exact
                to="/reports"

                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fas fa-file-alt mr-2" className='fourheading'>
                  Report Mgmt
                </CDBSidebarMenuItem>
              </NavLink>

              <NavLink
                exact
                to="/candidates"

                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fas fa-users mr-2" className='fourheading'>
                  Candidate Mgmt
                </CDBSidebarMenuItem>
              </NavLink>


            </CDBSidebarMenu>
            : null}
          {type == "Head recuiter" ?
            <CDBSidebarMenu>



              <NavLink
                exact
                to="/head_requirements"

                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fas fa-briefcase" className='fourheading'>
                  Requirement Mgmt
                </CDBSidebarMenuItem>
              </NavLink>

              <NavLink
                exact
                to="/candidates"

                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fas fa-user mr-2" className='fourheading'>
                  Candidate Mgmt
                </CDBSidebarMenuItem>
              </NavLink>


            </CDBSidebarMenu>
            : null}
          {type == "Recuiter" ?
            <CDBSidebarMenu>



              <NavLink
                exact
                to="/recruiter_requirements"

                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fas fa-briefcase" className='fourheading'>
                  Requirement Mgmt
                </CDBSidebarMenuItem>
              </NavLink>

              <NavLink
                exact
                to="/candidates"

                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fas fa-user mr-2" className='fourheading'>
                  Candidate Mgmt
                </CDBSidebarMenuItem>
              </NavLink>


            </CDBSidebarMenu>
            : null}

        </CDBSidebarContent>


      </CDBSidebar >
    </div >
  );
}

export default Sidebar;
{/* <CDBSidebarMenu>

  <NavLink
    exact
    to="/profilesettings"
    activeClassName="activeClicked"
  >
    <CDBSidebarMenuItem icon="fas fa-cogs" className='fourheading' >
      Setting
    </CDBSidebarMenuItem>

  </NavLink>

  {type == "Super admin" ?
    <>

      <NavLink
        exact
        to="/emailcontents"
        activeClassName="activeClicked"
      >
        <CDBSidebarMenuItem icon="far fa-envelope" className='fourheading'>
          Email Mgmt
        </CDBSidebarMenuItem>
      </NavLink>
      <NavLink
        exact
        to="/organizations"
        activeClassName="activeClicked"
      >
        <CDBSidebarMenuItem icon="fas fa-sitemap" className='fourheading'>
          Organization Mgmt
        </CDBSidebarMenuItem>
      </NavLink>
      <NavLink
        exact
        to="/user"

        activeClassName="activeClicked"
      >
        <CDBSidebarMenuItem icon="fas fa-user-cog" className='fourheading'>
          Admin Mgmt
        </CDBSidebarMenuItem>
      </NavLink>
    </>

    :
    type == "Admin" ?

      <>


        <NavLink
          exact
          to="/staffs"

          activeClassName="activeClicked"
        >
          <CDBSidebarMenuItem icon="fas fa-users" className='fourheading'>
            Staff Mgmt
          </CDBSidebarMenuItem>
        </NavLink>

        <NavLink
          exact
          to="/jobtype"

          activeClassName="activeClicked"
        >
          <CDBSidebarMenuItem icon="fas fa-briefcase" className='fourheading'>
            Job Type Mgmt
          </CDBSidebarMenuItem>
        </NavLink>


        <NavLink
          exact
          to="/visatype"

          activeClassName="activeClicked"
        >
          <CDBSidebarMenuItem icon="fas fa-plane" className='fourheading'>
            Visa Type Mgmt
          </CDBSidebarMenuItem>
        </NavLink>

        <NavLink
          exact
          to="/jobhistory"

          activeClassName="activeClicked"
        >
          <CDBSidebarMenuItem icon="far fa-clock" className='fourheading'>
            Job History
          </CDBSidebarMenuItem>
        </NavLink>

        <NavLink
          exact
          to="/candidates"

          activeClassName="activeClicked"
        >
          <CDBSidebarMenuItem icon="fas fa-users" className='fourheading'>
            Candidate History
          </CDBSidebarMenuItem>
        </NavLink>

      </>
      : type == "Account manager" ?
        <>

          <NavLink
            exact
            to="/partners"

            activeClassName="activeClicked"
          >
            <CDBSidebarMenuItem icon="fas fa-user" className='fourheading'>
              Partner Mgmt
            </CDBSidebarMenuItem>
          </NavLink>

          <NavLink
            exact
            to="/requirements"

            activeClassName="activeClicked"
          >
            <CDBSidebarMenuItem icon="fas fa-briefcase" className='fourheading'>
              Requirement Mgmt
            </CDBSidebarMenuItem>
          </NavLink>

          <NavLink
            exact
            to="/shortlists"

            activeClassName="activeClicked"
          >
            <CDBSidebarMenuItem icon="fas fa-check-circle mr-2" className='fourheading'>
              Short List Mgmt
            </CDBSidebarMenuItem>
          </NavLink>

          <NavLink
            exact
            to="/reports"

            activeClassName="activeClicked"
          >
            <CDBSidebarMenuItem icon="fas fa-file-alt mr-2" className='fourheading'>
              Report Mgmt
            </CDBSidebarMenuItem>
          </NavLink>

          <NavLink
            exact
            to="/candidates"

            activeClassName="activeClicked"
          >
            <CDBSidebarMenuItem icon="fas fa-users mr-2" className='fourheading'>
              Candidate Mgmt
            </CDBSidebarMenuItem>
          </NavLink>

        </>

        : type == "Head recuiter" ?
          <>


            <NavLink
              exact
              to="/head_requirements"

              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem icon="fas fa-briefcase" className='fourheading'>
                Requirement Mgmt
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink
              exact
              to="/candidates"

              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem icon="fas fa-user mr-2" className='fourheading'>
                Candidate Mgmt
              </CDBSidebarMenuItem>
            </NavLink>
          </>
          : type == "Recuiter" ?
            <>


              <NavLink
                exact
                to="/recruiter_requirements"

                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fas fa-briefcase" className='fourheading'>
                  Requirement Mgmt
                </CDBSidebarMenuItem>
              </NavLink>

              <NavLink
                exact
                to="/candidates"

                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fas fa-user mr-2" className='fourheading'>
                  Candidate Mgmt
                </CDBSidebarMenuItem>
              </NavLink>
            </>
            : null}

</CDBSidebarMenu> */}