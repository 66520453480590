import React, { useState } from "react";
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import './Profile.css';
import Select from 'react-select';


const options1 = [

    { value: 'Krish', label: 'Krish' },

];
const options2 = [

    { value: 'Krish001@gmail', label: 'Krish001@gmail' },

];
const options3 = [

    { value: '4528761302', label: '4528761302' },

];
const options4 = [

    { value: 'MCA', label: 'MCA' },

];

export const AccountCandidate = () => {

    const [Name, setName] = useState(null);
    const [EmailCandidate, setEmailCandidate] = useState(null);
    const [ConatctNo, setConatctNo] = useState(null);
    const [Qualification, setQualification] = useState(null);


    return (
        <div className="d-flex">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}


                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Candidate Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">List of Candidate</h6>




                                </header>


                                <div className="Organ_dropdown1 Candidate-dropdown  ">
                                    <div className="Organ_dropdown">
                                        <select id="dropdown">
                                            <option value="1">10</option>
                                            <option value="2">20</option>
                                            <option value="3">40</option>
                                            <option value="4">50</option>

                                        </select>
                                        <h5 className="recordpage">records per page</h5>
                                    </div>
                                    <div className="Organ_dropdown">
                                        <h5 className="recordpage">Search</h5>
                                        <input type="text" className="search_width" />
                                    </div>
                                </div>

                                <div className="row ">


                                    <div className="col-md-6 candidate-row">

                                        <div className="">
                                            <label for="inputPassword3" className="col-sm-3 text-candidate ">Name</label>
                                            <div className="col-sm-7">

                                                <div className="">
                                                    <Select
                                                        placeholder="[-Select Name-]"
                                                        defaultValue={Name}
                                                        onChange={setName}
                                                        options={options1}
                                                        styles={{
                                                           
                                                            placeholder: (base) => ({
                                                                ...base,
                                                                fontSize: '13px', // Change the font size as needed
                                                            }),
                                                        }}
                                                      
                                                    />
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-6">

                                        <div className="" >
                                            <label for="inputPassword3" className="col-sm-3 text-candidate ">Email</label>
                                            <div className="col-sm-7">

                                                <div className="">
                                                    <Select
                                                        placeholder="[-Select Email-]"
                                                        defaultValue={EmailCandidate}
                                                        onChange={setEmailCandidate}
                                                        options={options2}
                                                        styles={{
                                                           
                                                            placeholder: (base) => ({
                                                                ...base,
                                                                fontSize: '13px', // Change the font size as needed
                                                            }),
                                                        }}
                                                    />
                                                </div>


                                            </div>
                                        </div>

                                    </div>


                                    <div className="col-md-6 candidate-row">

                                        <div className="">
                                            <label for="inputPassword3" className="col-sm-3 text-candidate">Contact.No</label>
                                            <div className="col-sm-7">

                                                <div className="">
                                                    <Select
                                                        placeholder="[-Select Contact no-]"
                                                        defaultValue={ConatctNo}
                                                        onChange={setConatctNo}
                                                        options={options3}
                                                        styles={{
                                                           
                                                            placeholder: (base) => ({
                                                                ...base,
                                                                fontSize: '13px', // Change the font size as needed
                                                            }),
                                                        }}
                                                    />
                                                </div>


                                            </div>
                                        </div>

                                    </div>


                                    <div className="col-md-6">

                                        <div className="">
                                            <label for="inputPassword3" className="col-sm-3 text-candidate">Qualification</label>
                                            <div className="col-sm-7">

                                                <div className="">
                                                    <Select
                                                        placeholder="[-Select Qualification-]"
                                                        defaultValue={Qualification}
                                                        onChange={setQualification}
                                                        options={options4}
                                                        styles={{
                                                           
                                                            placeholder: (base) => ({
                                                                ...base,
                                                                fontSize: '13px', // Change the font size as needed
                                                            }),
                                                        }}
                                                    />
                                                </div>


                                            </div>
                                        </div>

                                    </div>






                                </div>



                                <div className="table-colorOrgan mob-overflow" >
                                    <table>
                                        <thead >
                                            <tr>
                                                <th className="color">S.No</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Name</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>No.Requirement</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Contact No.</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Qualification</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Resume</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>1</td>
                                                <td style={{ color: 'blue' }}>Krish</td>
                                                <td>1</td>
                                                <td>2234962613</td>
                                                <td>MCA</td>
                                                <td>
                                                    <i className="fas fa-download">Download</i>
                                                   
                                                </td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="dropdown_alert">
                                                            <span className="">▼</span>
                                                        </button>
                                                        <div className="dropdown-content">
                                                            <a href="/AcCandidateView"> View</a>
                                                            <a href="/AcCandimgmtEdit"> Edit</a>
                                                            <a href=""> Delete</a>


                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>2</td>
                                                <td style={{ color: 'blue' }}>Johnson</td>
                                                <td>1</td>
                                                <td>2347861201</td>
                                                <td>MBA</td>
                                                <td>
                                                    <i className="fas fa-download">Download</i>
                                                   
                                                </td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="dropdown_alert">
                                                            <span className="">▼</span>
                                                        </button>
                                                        <div className="dropdown-content">
                                                            <a href="/AcCandidateView"> View</a>
                                                            <a href="/AcCandimgmtEdit"> Edit</a>
                                                            <a href=""> Delete</a>


                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>3</td>
                                                <td style={{ color: 'blue' }}>Andrew</td>
                                                <td>3</td>
                                                <td>6213469732</td>
                                                <td>MCA</td>
                                                <td>
                                                    <i className="fas fa-download">Download</i>
                                                   
                                                </td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="dropdown_alert">
                                                            <span className="">▼</span>
                                                        </button>
                                                        <div className="dropdown-content">
                                                            <a href="/AcCandidateView"> View</a>
                                                            <a href="/AcCandimgmtEdit"> Edit</a>
                                                            <a href=""> Delete</a>


                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>


                                            <tr>
                                                <td>4</td>
                                                <td style={{ color: 'blue' }}>Ortan</td>
                                                <td>5</td>
                                                <td>2234962613</td>
                                                <td>MCA</td>
                                                <td>
                                                    <i className="fas fa-download">Download</i>
                                                   
                                                </td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="dropdown_alert">
                                                            <span className="">▼</span>
                                                        </button>
                                                        <div className="dropdown-content">
                                                            <a href="/AcCandidateView"> View</a>
                                                            <a href="/AcCandimgmtEdit"> Edit</a>
                                                            <a href=""> Delete</a>


                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>5</td>
                                                <td style={{ color: 'blue' }}>Tessa</td>
                                                <td>6</td>
                                                <td>2234962613</td>
                                                <td>MCA</td>
                                                <td>
                                                    <i className="fas fa-download">Download</i>
                                                   
                                                </td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="dropdown_alert">
                                                            <span className="">▼</span>
                                                        </button>
                                                        <div className="dropdown-content">
                                                            <a href="/AcCandidateView"> View</a>
                                                            <a href="/AcCandimgmtEdit"> Edit</a>
                                                            <a href=""> Delete</a>



                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>6</td>
                                                <td style={{ color: 'blue' }}>Scott</td>
                                                <td>4</td>
                                                <td>2234962613</td>
                                                <td>MCA</td>
                                                <td>
                                                    <i className="fas fa-download">Download</i>
                                                   
                                                </td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="dropdown_alert">
                                                            <span className="">▼</span>
                                                        </button>
                                                        <div className="dropdown-content">
                                                            <a href="/AcCandidateView"> View</a>
                                                            <a href="/AcCandimgmtEdit"> Edit</a>
                                                            <a href=""> Delete</a>


                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>



                                        </tbody>
                                    </table>
                                </div>

                                <div className="Previous_organ ">
                                    <div>
                                        <h5 style={{ fontSize: '13px', fontWeight: '600' }}>Showing 1 to 6 of 6 entries</h5>
                                    </div>
                                    <div>
                                        <button className="Next_button"> ← Previous</button>
                                        <button className="no1_button">1</button>
                                        <button className="Next_button">Next →</button>
                                    </div>
                                </div>


                            </section>

                        </div>





















                    </div>
                </div>
            </div>
        </div>










    );
}

