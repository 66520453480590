import React, { useEffect, useState } from "react";
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import Select from 'react-select';
import { ApiCall } from "../../../Config/API";
import { Link } from "react-router-dom/cjs/react-router-dom";
import swal from "sweetalert";
import { RequirementNoteModal } from "../../../Components/RequirementNoteModal";
import { PaginationContent } from "../../../Components/PaginationContent";
import { Search_PageDrop } from "../../../Components/Search_PageDrop";
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";
// import Search from 'react-select';

const Headoption = [

    { value: 'Robert', label: 'Robert' },

];


export const Recruiter_requirementList = () => {

    const [Headreq, setHeadreq] = useState(null);

    const [Requirment_List, setRequirment_List] = useState([]);
    const [requirement_id, setRequirement_id] = useState('');
    const [notesmodal, setNotesmodal] = useState(false);

  const [query, setQuery] = useState({
        per_page: 10,
        page: 1,
        search: '',
        sort_by: "",
        sort_direction: ''

    });
    useEffect(() => {


        GetApi(query)

    }, [])

    const { dispatch } = useApiResponseContext();

    const GetApi = async (query) => {



        var data = {
            "method": "GET",
            "route_name": "requirements?",
            'query':query

        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response", Response.success.data)
            setRequirment_List(Response.success.data)
            dispatch({ type: 'SUCCESS', payload: Response.success });


        }
        else {
            console.log("Error", Response.error)

        }

    }


    const SelectCandidate = async () => {



    }

    const statusChangeFunc = async (rec_id, index) => {


        var data = {
            "method": "POST",
            "route_name": "requirements/" + rec_id + "/opens"

        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response.success====>", Response.success)


            var array = [...Requirment_List]
            array[index].status.name = (array[index].status.name != "New" ? "New" : 'Open')
            setRequirment_List(array)


            swal("Success!", "Recruiter Status updated successfully", "success")

            setTimeout(() => {
                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error)

        }


    }

    const PageOnpresFunc = (pagedrop, value,) => {

        var post_data = {
            per_page: pagedrop,
            page: value,
            search: query.search,
            sort_by: "",
            sort_direction: query.sort_direction
        }
        console.log("value", value)

        setQuery(post_data)

        GetApi(post_data);

    }


    const OnSearchFunc = (value) => {


        var post_data = {
            per_page: query.per_page,
            page: 1,
            search: value,
            sort_by: "",
            sort_direction: ''
        }
        console.log("value", value)
        setQuery(post_data)
        GetApi(post_data);

    }



    return (
        <div className="d-flex">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}


                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Requirement Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">List of Requirement</h6>

                                </header>

                                <Search_PageDrop OnSearch={(value) => OnSearchFunc(value)} PageDropClick={(value) => PageOnpresFunc(value, 1)} />




                                <div className="table-colorOrgan mob-overflow">
                                    <table >
                                        <thead >
                                            <tr>
                                                <th className="color">S.No</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Requirement No.</th>
                                                <th className="color">Requirement Title</th>
                                                <th className="color">No.Candidate</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Status</th>
                                                <th style={{ textAlign: "center" }} className="color"><i className="fas fa-sort padding_sort"></i>Current Status</th>
                                                <th style={{ textAlign: "center" }} className="color"><i className="fas fa-sort padding_sort"></i>Action</th>
                                                <th style={{ textAlign: "center" }} className="color">Candidate</th>
                                                <th className="color">Notes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Requirment_List.map((e, i) => (
                                                <tr >

                                                  <td>{((query.page - 1) * 10 + 1) + i}</td>
                                                    <td>{e.requirement_no}</td>
                                                    <td>{e.title}</td>
                                                    <td>( {e.candidates_count} )</td>
                                                    <td>{e.status.name}</td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {e.status.name == "New" ?

                                                            <Link onClick={() => statusChangeFunc(e.id, i)} >Click to Open</Link>

                                                            :
                                                            <p>-</p>
                                                        }
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <div className="dropdown history_dropdown">
                                                            <button className="dropdown_alert">
                                                                <span className="">▼</span>
                                                            </button>
                                                            <div className="dropdown-content">

                                                                <Link to={`/recruiter_requirements_view/${e.id}`} >View</Link>


                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {e.status.name == "Open" ?
                                                            // <Link to="/RecruiterView" onClick={() => SelectCandidate()} >Select</Link>
                                                            <Link to={`/head_selectCandidate_add/${e.id}`}>Select</Link>

                                                            :
                                                            <p>-</p>
                                                        }
                                                    </td>
                                                    <td>
                                                        {/* <img alt="panelImage" src="img/pane/notes.jpg" className='logoimgnav2' /> */}
                                                        <Link onClick={() => (setNotesmodal(true), setRequirement_id(e.id))}>
                                                            <img alt="panelImage" src="img/pane/notes.jpg" className='logoimgnav2' />
                                                        </Link>

                                                    </td>



                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>


                                <PaginationContent PageClick={(value) => PageOnpresFunc(query.per_page, value)} />


                            </section>

                        </div>








                        {notesmodal ?
                            <RequirementNoteModal req_id={requirement_id} closemodal={(value) => value && setNotesmodal(false)} />
                            : null}








                    </div>
                </div>
            </div >
        </div >










    );
}

