import React, { Fragment } from "react";
import { BrowserRouter, Route, } from "react-router-dom";
import { ProfileSettings } from "./pages/Settings/ProfileSettings";
import { EmailList } from "./pages/SuperAdmin/EmailMgmt/EmailList";
import { UsersList } from "./pages/SuperAdmin/AdminMgmt/UsersList";
import { OraganizationList, } from "./pages/SuperAdmin/OrganizationMgmt/OraganizationList";
import { OrganizationAdd } from "./pages/SuperAdmin/OrganizationMgmt/OrganizationAdd";
import { UserAdd } from "./pages/SuperAdmin/AdminMgmt/UserAdd";
import { Email_Edit } from "./pages/SuperAdmin/EmailMgmt/Email_Edit";
import { OrganizationView } from "./pages/SuperAdmin/OrganizationMgmt/OrganizationView";
import { UserView } from "./pages/SuperAdmin/AdminMgmt/UserView";
import { UserEdit } from "./pages/SuperAdmin/AdminMgmt/UserEdit";
import { OrganizationEdit } from "./pages/SuperAdmin/OrganizationMgmt/OrganizationEdit";
import { AdminSettings } from "./pages/Ad_Settings";
import { StaffList } from "./pages/Admin/StaffMgmt/StaffList";
import { StaffAdd } from "./pages/Admin/StaffMgmt/StaffAdd";
import { JobType_List } from "./pages/Admin/JobType_Mgmt/JobType_List";
import { JobType_Add } from "./pages/Admin/JobType_Mgmt/JobType_Add";
import { VisaType_List } from "./pages/Admin/VisaType_Mgmt/VisaType_List";
import { VisaType_Add, } from "./pages/Admin/VisaType_Mgmt/VisaType_Add";
import { Jobhistory_List } from "./pages/Admin/Job_History/Jobhistory_List";
import { CandidateHistory } from "./pages/Ad_candidate";
// import { CandidateAdd } from "./pages/Ad_candiadd";
import { JobhistoryEdit } from "./pages/Ad_jobhistoryedit";
import { Managersettings } from "./pages/Mg_settings";
import { PartnerList } from "./pages/AccountManager/PartnerMgmt/PartnerList";
import { PartnerAdd } from "./pages/AccountManager/PartnerMgmt/PartnerAdd";
import { RequirementList } from "./pages/AccountManager/Requirement_Mgmt/RequirementList";
import { RequirementAdd } from "./pages/AccountManager/Requirement_Mgmt/RequirementAdd";
import { ShortList } from "./pages/AccountManager/ShortList_Mgmt/ShortList";
import { AccountCandidate } from "./pages/Ac_candidatemgmt";
import { ReportList } from "./pages/AccountManager/Report_Mgmt/ReportList";
import { HeadSettings } from "./pages/Head_Setting";
import { RecruiterSettings } from "./pages/Recruiter_setting";
import { Head_RequirementList } from "./pages/HeadRecruiter/Requirement_Mgmt/Head_RequirementList";
import { Head_RequirementView } from "./pages/HeadRecruiter/Requirement_Mgmt/Head_RequirementView";

import { Head_MyRequirementList } from "./pages/HeadRecruiter/Requirement_Mgmt/Head_MyRequirementList";
import { Head_Assign_Requirement_List } from "./pages/HeadRecruiter/Requirement_Mgmt/Head_Assign_Requirement_List";
import { RequirementViewHead } from "./pages/Head_reqAss_View";
import { RequirementViewMyView } from "./pages/Head_req_viewmyview";

import { CandidateAdd } from "./pages/Candidate_Mgmt/CandidateAdd";
import { CandidateList } from "./pages/Candidate_Mgmt/CandidateList";
import { CandidateView } from "./pages/Candidate_Mgmt/CandidateView";
import { CandidateEdit } from "./pages/Candidate_Mgmt/CandidateEdit";

import { Recruiter_requirementList } from "./pages/Recruiter/Requirement_Mgmt/Recruiter_requirementList";
import { RecruiterCandiate } from "./pages/Recruiter_reqCandi";
import { RecruiterView } from "./pages/Recruiter_reqMainView";
import { RecruiterMainCandidate } from "./pages/Recruiter_CabdiMain";
import { RecruiterCandidateView } from "./pages/Recruiter_CandiView";
import { RecruiterCandidateEdit } from "./pages/Recruiter_CandiEdit";
import { My_Shortlist } from "./pages/AccountManager/ShortList_Mgmt/My_Shortlist";
import { Partner_Shortlist_View } from "./pages/AccountManager/ShortList_Mgmt/Partner_Shortlist_View";

import { Partner_Shortlist } from "./pages/AccountManager/ShortList_Mgmt/Partner_Shortlist";
import { ShortList_Candidates } from "./pages/AccountManager/ShortList_Mgmt/ShortList_Candidates";
import { AcCandidateView } from "./pages/Ac_CandimgmtView";
import { AcCandimgmtEdit } from "./pages/Ac_CandigmtEdit";

import { StaffEdit } from "./pages/Admin/StaffMgmt/StaffEdit";
// import { AdJobEdit } from "./pages/Ad_jobEdit";
import { JobType_Edit } from "./pages/Admin/JobType_Mgmt/JobType_Edit";

import { VisaType_Edit } from "./pages/Admin/VisaType_Mgmt/VisaType_Edit";
import { PartnerEdit } from "./pages/AccountManager/PartnerMgmt/PartnerEdit";
import { PartnerView } from "./pages/AccountManager/PartnerMgmt/PartnerView";
import { Assign_Requirement_List } from "./pages/AccountManager/Requirement_Mgmt/Assign_Requirement_List";
import { RequirementEdit } from "./pages/AccountManager/Requirement_Mgmt/Requirment_Edit";
import { RequirementView } from "./pages/AccountManager/Requirement_Mgmt/requrement_view";

import { LoginContent } from "./pages/Login/Login";
import { Spinner } from "./Components/Spinner";
import { Redirect, Router, Switch } from "react-router-dom/cjs/react-router-dom";
import { StaffView } from "./pages/Admin/StaffMgmt/StaffView";
import { ChangePassword } from "./pages/Settings/ChangePassword";

import Page404 from "./pages/Page404/Page404";
import { Assign_Requrement_Edit } from "./pages/AccountManager/Requirement_Mgmt/Assign_Requrement_Edit";
import { Assign_Requrement_View } from "./pages/AccountManager/Requirement_Mgmt/Assign_Requrement_View";
import { Head_Assign_Requirement_View } from "./pages/HeadRecruiter/Requirement_Mgmt/Head_Assign_Requirement_View";
import { Head_MyRequirement_View } from "./pages/HeadRecruiter/Requirement_Mgmt/Head_MyRequirement_View";

import { Head_SelectCandidate_Add } from "./pages/HeadRecruiter/Requirement_Mgmt/Head_SelectCandidate_Add";
import { Assign_Req_Cand_View } from "./pages/AccountManager/Requirement_Mgmt/Assign_Req_Cand_View";
import { Assign_Req_Cand_Edit } from "./pages/AccountManager/Requirement_Mgmt/Assign_Req_Cand_Edit";
import { Recruiter_requirement_View } from "./pages/Recruiter/Requirement_Mgmt/Recruiter_requirement_View";
import { Partner_Shortlist_Edit } from "./pages/AccountManager/ShortList_Mgmt/Partner_Shortlist_Edit";
import { Jobhistory_View } from "./pages/Admin/Job_History/Jobhistory_View.";
import { Jobhistory_Edit } from "./pages/Admin/Job_History/Jobhistory_Edit";
import { History_Req_Cand_View } from "./pages/Admin/Job_History/History_Req_Cand_View";




const Routes = () => {

  const Layout = ({ children }) => (
    <div >
      {/* Your Spinner component here */}
      <Spinner />
      {children}
    </div>
  );



  const token = localStorage.getItem("IDMS_TOKEN");



  return (

    <Switch>
      {/* <Layout> */}
      {/* Super Admin page 1 */}


      {/* <Route exact path="/" component={(routeProps) => (token ? <Redirect to="profilesettings" {...routeProps} /> : <Redirect to="login" {...routeProps} />)} /> */}
      {/* <Route path="*" component={Page404} /> */}
      {/* <Route path="/login" component={LoginContent} /> */}
      <Route path="/changepassword" component={ChangePassword}></Route>
      <Route path="/profilesettings" component={ProfileSettings} />
      {/* organizations start*/}
      <Route path="/organizations" component={OraganizationList} />
      <Route path="/organizations_add" component={OrganizationAdd} />
      <Route path="/organization_edit/:id" component={OrganizationEdit} />
      <Route path="/organization_view/:id" component={OrganizationView} />


      <Route path="/emailcontents" component={EmailList} />
      <Route path="/emailcontents_edit/:id" component={Email_Edit} />


      {/* organizations end */}

      {/* Admin start*/}
      <Route path="/user" component={UsersList} />
      <Route path="/user_add" component={UserAdd} />
      <Route path="/user_edit/:id" component={UserEdit} />
      <Route path="/user_view/:id" component={UserView} />
      {/* Admin  end*/}


      {/* Email  start */}
      {/* Email  end */}

      {/* Staff  start */}

      <Route path="/staffs" component={StaffList} />
      <Route path="/staffs_add" component={StaffAdd} />
      <Route path="/staffs_edit/:id" component={StaffEdit} />
      <Route path="/staffs_view/:id" component={StaffView} />

      {/* Staff  end */}


      {/* Jobtype start */}


      <Route path="/jobtype" component={JobType_List} />
      <Route path="/jobtype_add" component={JobType_Add} />
      <Route path="/jobtype_edit/:id" component={JobType_Edit} />

      {/* Jobtype end */}

      {/* visatype start */}

      <Route path="/visatype" component={VisaType_List} />
      <Route path="/visatype_add" component={VisaType_Add} />
      <Route path="/visatype_edit/:id" component={VisaType_Edit} />
      {/* visatype end */}

      <Route path="/jobhistory" component={Jobhistory_List} />

      <Route path="/jobhistory_view/:id" component={Jobhistory_View} />

      <Route path="/jobhistory_edit/:id" component={Jobhistory_Edit} />
      <Route path="/jobhistory_candidates_view/:req_id/:cand_id" component={History_Req_Cand_View} />


      {/* <Route path="/CandidateHistory" component={CandidateHistory} /> */}
      {/* <Route path="/ CandidateAdd" component={CandidateAdd} /> */}
      <Route path="/JobhistoryEdit" component={JobhistoryEdit} />

      {/* Partner Start  */}

      <Route path="/partners" component={PartnerList} />
      <Route path="/partners_add" component={PartnerAdd} />
      <Route path="/partners_edit/:id" component={PartnerEdit} />
      <Route path="/partners_view/:id" component={PartnerView} />
      {/* Partner End  */}

      <Route path="/requirements" component={RequirementList} />
      <Route path="/requirements_add" component={RequirementAdd} />
      <Route path="/requirements_edit/:id" component={RequirementEdit} />
      <Route path="/requirements_view/:id" component={RequirementView} />

      <Route path="/assigned_requirement" component={Assign_Requirement_List} />
      <Route path="/assigned_requirement_view/:id" component={Assign_Requrement_View} />
      <Route path="/assigned_requirement_edit/:id" component={Assign_Requrement_Edit} />

      <Route path="/req_candidates_view/:req_id/:cand_id" component={Assign_Req_Cand_View} />
      <Route path="/req_candidates_edit/:req_id/:cand_id" component={Assign_Req_Cand_Edit} />

      <Route path="/shortlists" component={ShortList} />
      <Route path="/shortlists_candidateslist/:id" component={ShortList_Candidates} />
      <Route path="/my_shortlists" component={My_Shortlist} />
      <Route path="/partner_shortlists" component={Partner_Shortlist} />
      <Route path="/partner_shortlists_view/:id" component={Partner_Shortlist_View} />
      <Route path="/partner_shortlists_edit/:id" component={Partner_Shortlist_Edit} />

      <Route path="/reports" component={ReportList} />


      <Route path="/AccountCandidate" component={AccountCandidate} />
      <Route path="/AcCandidateView" component={AcCandidateView} />
      <Route path="/AcCandimgmtEdit" component={AcCandimgmtEdit} />


      {/*Head Recruiter page4 */}

      {/* <Route path="/HeadSettings" component={HeadSettings} /> */}
      <Route path="/head_requirements" component={Head_RequirementList} />
      <Route path="/head_requirements_view/:id" component={Head_RequirementView} />



      <Route path="/head_myrequirements_list" component={Head_MyRequirementList} />
      <Route path="/head_myrequirement_view/:id" component={Head_MyRequirement_View} />

      <Route path="/head_assignrequirements_list" component={Head_Assign_Requirement_List} />
      <Route path="/head_assignrequirements_view/:id" component={Head_Assign_Requirement_View} />


      <Route path="/head_selectCandidate_add/:id" component={Head_SelectCandidate_Add} />





      <Route path="/RequirementViewHead" component={RequirementViewHead} />
      <Route path="/RequirementViewMyView" component={RequirementViewMyView} />



      <Route path="/candidates" component={CandidateList} />
      <Route path="/candidates_add" component={CandidateAdd} />
      <Route path="/candidates_view/:id" component={CandidateView} />
      <Route path="/candidates_edit/:id" component={CandidateEdit} />



      {/* Recruiter page 5 */}
      {/* <Route path="/RecruiterSettings" component={RecruiterSettings} /> */}
      <Route path="/recruiter_requirements" component={Recruiter_requirementList} />
      <Route path="/recruiter_requirements_view/:id" component={Recruiter_requirement_View} />




      <Route path="/RecruiterView" component={RecruiterView} />
      <Route path="/RecruiterCandiate" component={RecruiterCandiate} />
      <Route path="/RecruiterMainCandidate" component={RecruiterMainCandidate} />
      <Route path="/RecruiterCandidateView" component={RecruiterCandidateView} />
      <Route path="/RecruiterCandidateEdit" component={RecruiterCandidateEdit} />

      {/* <Route path="*" component={Page404} /> */}

      {/* </Layout> */}

    </Switch>

  );
};

export default Routes;
