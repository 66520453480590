import React, { useEffect, useState } from "react";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import { Link } from 'react-router-dom';


import {
    CDBNavbar,
    CDBNavBrand,
    CDBNavbarNav,
    CDBNavToggle,
    CDBNavItem,
    CDBNavLink,
    CDBBtn,
    CDBCollapse
} from "cdbreact";
import '../../Profile.css';
import axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ADMIN_URL, ORGANIZATION_URL } from "../../../constant";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import moment from "moment";

// Admin page here as hero

export const UserView = () => {

    const location = useLocation();

    const { id } = useParams()
    const admin_id = id


    const [AdValq, setAdValq] = useState('');
    const [AdVal1q, setAdVal1q] = useState('');
    const [AdVal2q, setAdVal2q] = useState('');
    const [Createdq, setCreatedq] = useState('');


    const [AdVal, setAdVal] = useState('');
    const [AdVal1, setAdVal1] = useState('');
    const [AdVal2, setAdVal2] = useState('');
    const [AdVal3, setAdVal3] = useState('');
    const [Created, setCreated] = useState('');

    const [organizationdetails, setOrganizationdetails] = useState({})


    useEffect(() => {


        fetchData3();
    }, []);



    const fetchData3 = async () => {
        const token = localStorage.getItem("IDMS_TOKEN");


        try {
            const Response = await axios({
                method: "get",
                url: `${ADMIN_URL}/` + admin_id,
                headers: {
                    Authorization: token
                }
            })




            console.log("Response====>", Response.data.organization)
            setAdVal(Response.data.first_name)
            setAdVal1(Response.data.last_name)
            setAdVal2(Response.data.email)
            setAdVal3(Response.data.status.name)
            setCreated(Response.data.created_at)

            setOrganizationdetails(Response.data.organization)

        }

        catch (err) {
            console.log("errr====>", err.response.data.errors)
        }

    };


    return (

        <div className="d-flex">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}



                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Admin Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">View Admin</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" to="/user" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>




                                </header>
                                <div className="row Organization_sub">
                                    <div className="col-sm-12 col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">First Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {AdVal}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Last Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {AdVal1}
                                            </div>
                                        </div>
                                        {/* <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">User Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                            </div>
                                        </div> */}

                                    </div>

                                    <div className="col-md-6">

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Email ID</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {AdVal2}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Status</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                <span className={`${AdVal3 === 'Active' ? 'green-status' : AdVal3 === 'In Active' ? 'red-status' : 'yellow-status'}`}>{AdVal3}</span>
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Created on</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {moment(Created).format('MM-DD-YYYY hh:mm A')}

                                            </div>
                                        </div>



                                    </div>

                                </div>




                            </section>

                        </div><br></br>


                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Organization Details</h6>




                                </header>
                                <div className="row Organization_sub">
                                    <div className="col-sm-12 col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Organization</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {organizationdetails.company_name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Address</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {organizationdetails.address}

                                            </div>
                                        </div>


                                    </div>

                                    <div className="col-md-6">

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Email ID</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {organizationdetails.email}

                                            </div>
                                        </div>


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Mobile No.</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {organizationdetails.mobile}

                                            </div>
                                        </div>



                                    </div>

                                </div>
                                <div className="row Organization_sub">
                                    <div className="OrganView" >
                                        <label className="col-sm-2 control-label">Logo</label>
                                        <img alt="panelImage" src={organizationdetails.logo} className='logoimg-setting' />

                                    </div>


                                </div>


                            </section>

                        </div>




                    </div>
                </div>
            </div>
        </div>





    );
}
