import React, { useEffect, useState } from "react";
import { CDBBtn, CDBIframe, CDBView } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import { Link, useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { ApiCall } from "../../../Config/API";
import swal from "sweetalert";
import TextEditor from "../../../Components/TextEditor";
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'


export const Email_Edit = () => {

    const navigate = useHistory()


    const { id } = useParams()
    useEffect(() => {

        getAll();

    }, []);

    const [emailcontentsList, setEmailcontentsList] = useState({});

    const [content, setcontent] = useState('');

    const [formData, setFormData] = useState({
        title: '',
        from_name: '',
        from_email: '',
        content: '',
        to_email: '',
        subject: '',
    });
    const [error, seterror] = useState({})

    // Update form data when any input changes
    const handleInputChange = (e, type) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleContentChange = (value) => {

        setFormData({
            ...formData,
            ["content"]: value,
        });

    };
    const getAll = async (query) => {



        var data = {
            "method": "get",
            "route_name": `mail_contents/${id}/edit`,


        }
        const Response = await ApiCall(data)
        if (Response.success) {

            console.log("Response", Response.success)
            setFormData(Response.success)
            setcontent(Response.success.content)
            return


        }
        else {
            console.log("Error", Response.error.errors)


        };


    }

    const handleSubmit = async (query) => {



        var data = {
            "method": "PUT",
            "route_name": `mail_contents/${id}`,
            "body": {
                title: formData.title,
                from_name: formData.from_name,
                from_email:formData.from_email,
                content: content,
                to_email: formData.to_email,
                subject: formData.subject,
            }

        }
        const Response = await ApiCall(data)
        if (Response.success) {

            console.log("Response", Response.success)

            swal("Success!", "The emailcontent has been saved", "success")
            setTimeout(() => {
                navigate.push("/emailcontents")

                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error.errors)


        };


    }
    console.log("formData", formData)

    var modules = {
        toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] }
            ],
            [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ]
    };

    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "image", "align", "size",
    ];




    const handleProcedureContentChange = (content) => {
        console.log("content---->", content);
       setcontent(content)
        // setFormData({
        //     ...formData,
        //     ["content"]: content,
        // });

    };
    return (
        <div className="d-flex profile">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>


                        {/*  */}

                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Email Management</h4>
                        </div>



                        <div className="col-lg-12 ">

                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Edit Email Content </h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" to="/emailcontents" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>

                                </header>



                                <div className="row Organization_sub">

                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group form-textSetting">
                                            <label className="col-sm-3 control-label">User Name</label>
                                            <div className="col-sm-7" style={{ marginTop: '5px' }}>
                                                {formData.user_type?.type}
                                            </div>
                                        </div>

                                        <div className="form-group form-textSetting">
                                            <label className="col-sm-3 control-label">Email Title <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    value={formData.title}
                                                    onChange={handleInputChange}
                                                />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.title}</span>

                                            </div>
                                        </div>

                                        <div className="form-group form-textSetting">
                                            <label className="col-sm-3 control-label">From Name <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="from_name"
                                                    value={formData.from_name}
                                                    onChange={handleInputChange}
                                                />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.from_name}</span>

                                            </div>
                                        </div>

                                        <div className="form-group form-textSetting">
                                            <label className="col-sm-3 control-label">From Email <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="from_email"
                                                    value={formData.from_email}
                                                    onChange={handleInputChange}
                                                />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.from_email}</span>

                                            </div>
                                        </div>

                                        {/* <div className="form-group form-textSetting">
                                            <label className="col-sm-3 control-label">Content <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <textarea
                                                    type="text"
                                                    className="form-control email_content_text"
                                                    name="content"
                                                    value={formData.content}
                                                    onChange={handleInputChange}
                                                    placeholder=""
                                                ></textarea>
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.content}</span>

                                            </div>
                                        </div> */}
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group form-textSetting">
                                            <label className="col-sm-3 control-label">To Email <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="to_email"
                                                    value={formData.to_email}
                                                    onChange={handleInputChange}
                                                />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.to_email}</span>

                                            </div>
                                        </div>

                                        <div className="form-group form-textSetting">
                                            <label className="col-sm-3 control-label">Subject <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="subject"
                                                    value={formData.subject}
                                                    onChange={handleInputChange}
                                                />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.subject}</span>

                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group form-textSetting">
                                        <label for="inputEmail3" class="col-sm-3 control-label">Content</label>
                                        <div className="col-sm-7">
                                            {/* <textarea
                                                type="text"
                                                className="form-control email_content_text"
                                                name="content"
                                                value={formData.content}
                                                onChange={handleInputChange}
                                                placeholder=""
                                            ></textarea> */}
                                            {/* <TextEditor
                                                values={content}
                                                onChange={(value) => handleContentChange(value)}
                                            /> */}
                                            <ReactQuill
                                                theme="snow"
                                                modules={modules}
                                                value={content}
                                                formats={formats}
                                                placeholder="write your content ...."
                                                onChange={(value) => handleProcedureContentChange(value)}
                                                style={{ height: "220px" }}

                                            >
                                            </ReactQuill>

                                        </div>


                                    </div>


                                    {/* <button onClick={() => handleSubmit()} type="button" className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button> */}








                                </div>

                                <div style={{ textAlign: "center", paddingTop: 20 }} className="form-group">
                                    <div className=" col-sm-7 button_top">
                                        <button onClick={() => handleSubmit()} className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>
                                    </div>
                                </div>


                            </section>

                        </div>





                    </div>





                </div>
            </div>

        </div >






    );
}