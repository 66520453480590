import React, { useState } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import {
    CDBNavbar,
    CDBNavBrand,
    CDBNavbarNav,
    CDBNavToggle,
    CDBNavItem,
    CDBNavLink,
    CDBBtn,
    CDBCollapse
} from "cdbreact";
import '../pages/Profile.css';
import { Link } from "react-router-dom/cjs/react-router-dom";

// Admin page here as hero

export const RecruiterView = () => {



    return (

        <div className="d-flex">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}



                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Reactuirement Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">


                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">View Assigned Rquirement</h6>
                                    <div>
                                        <Link type="button" className=" btn btn-default Organ_addbtn" to="/RecruiterCandiate" ><i className="fa fa-plus-circle arrowkey"></i>Add Candidate</Link>
                                        <Link type="button" className=" btn btn-default Organ_addbtn" to="/recruiter_requirements" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>

                                    </div>
                                </header>
                                <div className="row Organization_sub">
                                    <div className="col-sm-12 col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Requirement No.</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                160322A
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Requirement Title</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                new rec 123
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Job Type</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                Full Time
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Job Location</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                new york
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Recruiter Rate (1099)</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                $ 100 Per Hour -  $ 200 Per Hour
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Recruiter Rate (W2)</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                $ 200 Per Hour -  $ 400 Per Hour
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Description</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                new recuriments of the test user  fgfg
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Notes</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                new recuriments of the test user
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Visa Restrictions</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                <span >US Citizens Only</span>
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Local Candidate Only</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                Yes
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Contract Length</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                22
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">No of Positions</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                5
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Created On</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                2016-03-22 12:08 PM
                                            </div>
                                        </div>

                                    </div>

                                </div>




                            </section>

                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Head Recruiter Detail</h6>




                                </header>
                                <div className="row Organization_sub">
                                    <div className="col-sm-12 col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                Davis Wilson
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Davis Wilson</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                demouserlt3@gmail.com
                                            </div>
                                        </div>


                                    </div>

                                    <div className="col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Contact No.</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                <span >-</span>
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Assigned On</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                2016-03-22 12:08 PM
                                            </div>
                                        </div>


                                    </div>

                                </div>




                            </section>

                        </div>







                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">List of Candidate</h6>




                                </header>


                                <div className="Organ_dropdown1  ">
                                    <div className="Organ_dropdown">
                                        <select id="dropdown">
                                            <option value="1">10</option>
                                            <option value="2">20</option>
                                            <option value="3">40</option>
                                            <option value="4">50</option>

                                        </select>
                                        <h5 className="recordpage">records per page</h5>
                                    </div>
                                    <div className="Organ_dropdown">
                                        <h5 className="recordpage">Search</h5>
                                        <input type="text" className="search_width" />
                                    </div>
                                </div>




                                <div className="table-colorOrgan mob-overflow">
                                    <table >
                                        <thead >
                                            <tr>
                                                <th className="color">S.No</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Name</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Requirement No.</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Contact No.</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Qualification</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Visa Restriction</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Resume</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Status</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Selected On</th>
                                                <th className="color">Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr className="tabledata">
                                                <td>1</td>
                                                <td style={{ color: 'blue' }}>Farina </td>
                                                <td>160322A</td>
                                                <td>9789306102</td>
                                                <td>MCA</td>
                                                <td>Green VT</td>
                                                <td > <i className="fas fa-download">Download</i></td>
                                                <td>Assigned to partner shortlist</td>
                                                <td>2016-03-22 12:14 PM</td>
                                                <td>
                                                    <div className="dropdown history_dropdown">
                                                        <button className="dropdown_alert">
                                                            <span className="">▼</span>
                                                        </button>
                                                        <div className="dropdown-content">
                                                            <a href="/ CandidateAdd"> View</a>


                                                        </div>
                                                    </div>
                                                </td>


                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                </div>

                                <div className="Previous_organ ">
                                    <div>
                                        <h5 style={{ fontSize: '13px', fontWeight: '600' }}>Showing 1 to 2 of 2 entries</h5>
                                    </div>
                                    <div>
                                        <button className="Next_button"> ← Previous</button>
                                        <button className="no1_button">1</button>
                                        <button className="Next_button">Next →</button>
                                    </div>
                                </div>


                            </section>

                        </div>




















                    </div>
                </div>
            </div>
        </div>





    );
}
