import React, { useEffect, useState } from "react";
import {
    CDBBtn,
    CDBProgress,
    CDBTable,
    CDBTableHeader,
    CDBTableBody,
    CDBContainer,
    CDBLink
} from "cdbreact";

import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import "../Dashboard.css";
import Select from 'react-select';
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import { ApiCall } from "../../Config/API";
import swal from "sweetalert";
import moment from "moment";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const candiState = [

    { value: 'India', label: 'India' },

];
const Joboption = [

    { value: 'Full Time', label: 'Full Time' },
    { value: 'Part Time', label: 'Part Time' }

];
export const CandidateAdd = () => {

    const [States, setStates] = useState([]);
    const navigate = useHistory()
    const [canVisa, setcanVisa] = useState(null);
    const [candidateSate, setcandidateSate] = useState(null);
    const [Visacoption, setVisacoption] = useState([]);

    const [rate, setRate] = useState([]);
    const [Error, setError] = useState({});
    const [dateshow, setdateshow] = useState("");
    const [datesent, setdatesent] = useState("");



    const [candidatedetails, setCandidatedetails] = useState({
        first_name: "",
        last_name: "",
        email: "",
        mobile: '',
        dob: "",
        location: '',
        city: '',
        state_id: '',
        zipcode: '',
        is_employer: -1,
        employer_rate_type_id: "",
        qualification: "",
        skills: "",
        visa_id: "",
        visa_attachment: "",
        reason: "",
        candidate_rate: "",
        resume: "",
        is_geographical: "",
        description: "",
        employer_name: ""

    });





    useEffect(() => {


        GetApi()

    }, [])

    const GetApi = async () => {



        var data = {
            "method": "GET",
            "route_name": "candidates/create",

        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response", Response.success)

            var array = Response.success.states.map((e) => ({

                value: e.id,
                label: e.name
            }))

            setStates(array)


            var array1 = Response.success.visas.map((e) => ({

                value: e.id,
                label: e.name
            }))

            setVisacoption(array1)
            setRate(Response.success.employer_rate_types)
        }
        else {
            console.log("Error", Response.error)

        }

    }


    const handleInputChange = (fieldName, e) => {



        console.log("e", e)
        if (fieldName == "is_employer" || fieldName == "is_geographical") {
            setCandidatedetails((prevData) => ({
                ...prevData,
                [fieldName]: e,
            }));
        }

        else if (fieldName == "state_id") {
            setCandidatedetails((prevData) => ({
                ...prevData,
                [fieldName]: e.value,
            }));
        }
        else if (fieldName == "visa_id") {
            setCandidatedetails((prevData) => ({
                ...prevData,
                [fieldName]: e.value,
            }));
        }
        else if (fieldName == "visa_attachment" || fieldName == "resume") {
            setCandidatedetails((prevData) => ({
                ...prevData,
                [fieldName]: e.target.files[0],
            }));
        }
        else {
            setCandidatedetails((prevData) => ({
                ...prevData,
                [fieldName]: e.target.value,
            }));
        }


    }

    const SubmitFuc = async () => {

        console.log("datesent", datesent)
        var formdata = new FormData();

        formdata.append("first_name", candidatedetails.first_name);
        formdata.append("last_name", candidatedetails.last_name);
        formdata.append("email", candidatedetails.email);
        formdata.append("mobile", candidatedetails.mobile);
        formdata.append("dob", datesent);
        formdata.append("location", candidatedetails.location);
        formdata.append("skills", candidatedetails.skills);
        formdata.append("city", candidatedetails.city);
        formdata.append("state_id", candidatedetails.state_id);
        formdata.append("zipcode", candidatedetails.zipcode);
        formdata.append("is_employer", candidatedetails.is_employer);
        formdata.append("employer_rate_type_id", candidatedetails.employer_rate_type_id);
        formdata.append("employer_name", candidatedetails.employer_name);
        formdata.append("qualification", candidatedetails.qualification);
        formdata.append("visa_id", candidatedetails.visa_id);
        formdata.append("visa_attachment", candidatedetails.visa_attachment);
        formdata.append("reason", candidatedetails.reason);
        formdata.append("candidate_rate", candidatedetails.candidate_rate);
        formdata.append("resume", candidatedetails.resume);

        formdata.append("is_geographical", candidatedetails.is_geographical);

        formdata.append("description", candidatedetails.description);

        // console.log("Response", candidatedetails.visa_attachment)





        var data = {
            "method": "POST",
            "route_name": "candidates",
            "body": formdata
        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response", Response.success)
            swal("Success!", "Candidate Added Sucessfully!", "success")
            setTimeout(() => {
                // navigate.push("/candidates")
                navigate.goBack()
                swal.close();

            }, 1500);

        }
        else {
            console.log("Errorrrr", Response.error.errors)
            setError(Response.error.errors)
        }

    }

    const DateOnchange = (date) => {

        setdateshow(date)

        var value = moment(date).format('MM/DD/YYYY')

        setdatesent(value)


    }


    return (
        <div className="dashboard d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>


                        {/*  */}

                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Candidate Management</h4>
                        </div>

                        <div className="container-fluid">
                            <div className="row borderbox">
                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Add Candidate</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn"
                                        //  to="/candidates" 
                                        onClick={() => navigate.goBack()}
                                    ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>

                                </header>


                                <div className="col-sm-12 col-lg-6" style={{ marginTop: '18px' }}>




                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">First Name <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input
                                                onChange={(e) => handleInputChange("first_name", e)}
                                                type="text" className="form-control validate[required,custom[phone]]" name="data[User][mobile]" required />
                                            {Error.first_name ?
                                                <span className="span-error" >{Error.first_name}</span>
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Last Name <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input
                                                onChange={(e) => handleInputChange("last_name", e)}
                                                type="text" className="form-control validate[required,custom[phone]]" name="data[User][mobile]" required />
                                            {Error.last_name ?
                                                <span className="span-error" >{Error.last_name}</span>
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Email <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input
                                                onChange={(e) => handleInputChange("email", e)}
                                                type="text" className="form-control validate[required,custom[phone]]" name="email" required />
                                            {Error.email ?
                                                <span className="span-error" >{Error.email}</span>
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Contact No. <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input
                                                onChange={(e) => handleInputChange("mobile", e)}
                                                type="text" className="form-control validate[required,custom[phone]]" maxLength={10} name="number" required />
                                            {Error.mobile ?
                                                <span className="span-error" >{Error.mobile}</span>
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Date Of Birth</label>
                                        <div className="col-sm-7">


                                            <DatePicker
                                                selected={dateshow}
                                                className="form-control"
                                                onChange={DateOnchange}
                                                dateFormat="MM-dd-yyyy"
                                                placeholderText="MM-DD-YYYY"

                                            />



                                            {Error.dob ?
                                                <span className="span-error" >{Error.dob}</span>
                                                : null}

                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Location <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input
                                                onChange={(e) => handleInputChange("location", e)}
                                                type="text" className="form-control validate[required,custom[phone]]" name="data[User][mobile]" required />
                                            {Error.location ?
                                                <span className="span-error" >{Error.location}</span>
                                                : null}

                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Address</label>
                                        <div className="col-sm-7">
                                            <textarea
                                                onChange={(e) => handleInputChange("address", e)}
                                                className="form-control inputbox-height validate[required]" id="address" cols="5" name="data[User][address]" ></textarea>
                                            {Error.address ?
                                                <span className="span-error" >{Error.address}</span>
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">City</label>
                                        <div className="col-sm-7">
                                            <input
                                                onChange={(e) => handleInputChange("city", e)}
                                                type="text" className="form-control validate[required,custom[phone]]" name="data[User][mobile]" required />

                                            {Error.city ?
                                                <span className="span-error" >{Error.city}</span>
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">State</label>
                                        <div className="col-sm-7">
                                            <div className="">
                                                <Select
                                                    placeholder="[-Select State"
                                                    onChange={(e) => handleInputChange("state_id", e)}
                                                    options={States}
                                                />

                                            </div>
                                            {Error.state_id ?
                                                <span className="span-error" >{Error.state_id}</span>
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Zipcode</label>
                                        <div className="col-sm-7">
                                            <input
                                                onChange={(e) => handleInputChange("zipcode", e)}
                                                type="text" className="form-control validate[required,custom[phone]]" name="number" required />
                                            {Error.zipcode ?
                                                <span className="span-error" >{Error.zipcode}</span>
                                                : null}
                                        </div>
                                    </div>

                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Employer <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <form className="checkbox_visa">
                                                <div className="form-check" >
                                                    <input
                                                        onChange={(e) => handleInputChange("is_employer", 1)}
                                                        className="form-check-input" type="radio" name="radioOptions" id="radioOption1" value="option1" />
                                                    <label className="form-check-label" for="radioOption1">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check checkmtop">
                                                    <input
                                                        onChange={(e) => handleInputChange("is_employer", 0)}
                                                        className="form-check-input" type="radio" name="radioOptions" id="radioOption2" value="option2" />
                                                    <label className="form-check-label" for="radioOption2">
                                                        No
                                                    </label>
                                                </div>

                                            </form>

                                            {Error.is_employer ?
                                                <span className="span-error" >{Error.is_employer}</span>
                                                : null}
                                        </div>

                                    </div>

                                    {candidatedetails.is_employer == 0 ?

                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-3 control-label">1099 or W2 <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <form className="checkbox_visa mar-top">
                                                    {rate.map((e, i) => (
                                                        <div className="form-check"
                                                        >
                                                            <input
                                                                checked={e.id == candidatedetails.employer_rate_type_id ? true : false}
                                                                value={e.id}
                                                                onChange={(value) => handleInputChange("employer_rate_type_id", value)} className="form-check-input" type="radio" name="radioOptions" id="radioOption1" />

                                                            <label className="form-check-label" for="radioOption1">
                                                                {e.type}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </form>
                                                {Error.employer_rate_type_id ?
                                                    <span className="span-error"  >{Error.employer_rate_type_id}</span>
                                                    : null}
                                            </div>

                                        </div>
                                        : null}
                                    {candidatedetails.is_employer == 1 ?
                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-3 control-label">Employer Name <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input
                                                    onChange={(e) => handleInputChange("employer_name", e)}
                                                    type="text" className="form-control validate[required,custom[phone]]" name="data[User][mobile]" required />

                                                {Error.employer_name ?
                                                    <span className="span-error" >{Error.employer_name}</span>
                                                    : null}
                                            </div>
                                        </div>

                                        : null}

                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Qualification <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input
                                                onChange={(e) => handleInputChange("qualification", e)}
                                                type="text" className="form-control validate[required,custom[phone]]" name="data[User][mobile]" required />

                                            {Error.qualification ?
                                                <span className="span-error" >{Error.qualification}</span>
                                                : null}
                                        </div>
                                    </div>


                                    {/* <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Job Type<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <div className="">
                                                <Select
                                                    defaultValue={Job}
                                                    onChange={setJob}
                                                    options={Joboption}
                                                />
                                            </div>
                                        </div>
                                    </div> */}



                                </div>
                                <div className="col-md-6" style={{ marginTop: '18px' }}>


                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Skills <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <textarea
                                                onChange={(e) => handleInputChange("skills", e)}
                                                className="form-control inputbox-height validate[required] note-style" id="address" cols="5" name="data[User][address]" ></textarea>

                                            {Error.skills ?
                                                <span className="span-error" >{Error.skills}</span>
                                                : null}

                                        </div>
                                    </div>

                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Visa Type <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <div className="">
                                                <Select
                                                    placeholder="[-Select vis Type-]"
                                                    onChange={(e) => handleInputChange("visa_id", e)}
                                                    options={Visacoption}
                                                />
                                            </div>
                                            {Error.visa_id ?
                                                <span className="span-error" >{Error.visa_id}</span>
                                                : null}
                                        </div>
                                    </div>






                                    <div className="form-group form-textSetting">
                                        <label for="inputPassword3" className="col-sm-3 control-label">Visa Attachment <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input
                                                onChange={(e) => handleInputChange("visa_attachment", e)}
                                                type="file" className="" />
                                            {Error.visa_attachment ?
                                                <span className="span-error" >{Error.visa_attachment}</span>
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Reason <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <textarea
                                                onChange={(e) => handleInputChange("reason", e)}
                                                className="form-control inputbox-height validate[required] note-style" id="address" cols="5" name="data[User][address]" ></textarea>

                                            {Error.reason ?
                                                <span className="span-error" >{Error.reason}</span>
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="inputPassword3" className="col-sm-3 control-label">Candidate Rate <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input
                                                onChange={(e) => handleInputChange("candidate_rate", e)}
                                                type="text" className="form-control inputbox-height validate[required]" />
                                            {Error.candidate_rate ?
                                                <span className="span-error" >{Error.candidate_rate}</span>
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="inputPassword3" className="col-sm-3 control-label">Resume <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input
                                                onChange={(e) => handleInputChange("resume", e)}
                                                type="file" className="" />
                                            {Error.resume ?
                                                <span className="span-error" >{Error.resume}</span>
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Geographical Constraints <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <form className="checkbox_visa">
                                                <div className="form-check" >
                                                    <input
                                                        onChange={(e) => handleInputChange("is_geographical", 1)}
                                                        className="form-check-input" type="radio" name="radioOptions" id="radioOption1" value="option1" />
                                                    <label className="form-check-label" for="radioOption1">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check checkmtop">
                                                    <input
                                                        onChange={(e) => handleInputChange("is_geographical", 0)}
                                                        className="form-check-input" type="radio" name="radioOptions" id="radioOption2" value="option2" />
                                                    <label className="form-check-label" for="radioOption2">
                                                        No
                                                    </label>
                                                </div>

                                            </form>
                                            {Error.is_geographical ?
                                                <span className="span-error" >{Error.is_geographical}</span>
                                                : null}
                                        </div>

                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Description</label>
                                        <div className="col-sm-7">
                                            <textarea
                                                onChange={(e) => handleInputChange("description", e)}
                                                className="form-control inputbox-height validate[required] note-style" id="address" cols="5" name="data[User][address]" ></textarea>
                                        </div>
                                    </div>

                                    <div className="form-group" >

                                        <div className=" col-sm-7" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <button onClick={() => SubmitFuc()} className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>
                                        </div>
                                    </div>






                                </div>







                            </div>




                        </div>



                        <div className="container-fluid background-color" >

                        </div>


























                    </div>
                </div>
            </div>







        </div >

    );
}
