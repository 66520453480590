import React, { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
import { CDBBtn, CDBIframe, CDBView, } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import "../../Profile.css"
import { PARTNER_URL } from "../../../constant";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom";
import swal from "sweetalert";
import { ApiCall } from "../../../Config/API";
import { Search_PageDrop } from "../../../Components/Search_PageDrop";
import { PaginationContent } from "../../../Components/PaginationContent";
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";
import { Spinner } from "../../../Components/Spinner";

export const PartnerList = () => {


    const navigate = useHistory()


    const [data1, setData1] = useState([]);

    const [query, setQuery] = useState({
        per_page: 10,
        page: 1,
        search: '',
        sort_by: "",
        sort_direction: ''

    });
    const { dispatch } = useApiResponseContext();

    const { apiState } = useApiResponseContext();
    useEffect(() => {

      
        fetchData2(query);


    }, []);



    // console.log("responseStateaaaaaaaa", apiState)

    const fetchData2 = async (query) => {
        // dispatch({ type: 'SUCCESS', payload: null, loading: true });
        // dispatch({ type: 'START_LOADING' });
        // dispatch({ type: 'SUCCESS', loading: true });
        // dispatch({ type: 'SUCCESS', payload: null, loading: true });

        var data = {
            "method": "get",
            "route_name": `partners?`,
            'query': query

        }
        const Response = await ApiCall(data)
        if (Response.success) {


            console.log("success", Response.success)

            setData1(Response.success.data)

            dispatch({ type: 'SUCCESS', payload: Response.success, loading: false });
            // return;
        }
        else {
            console.log("Error", Response.error.errors)
        }

    };




    const EditFunc1 = async (idd) => {

        console.log("fsfsf", idd)



        navigate.push('/partners_edit', {
            state: {
                "id": idd,
            }
        });

    }

    const ViewFunc1 = async (idd) => {

        console.log("fsfsf", idd)



        navigate.push('/partners_edit', {
            state: {
                "id": idd,
            }
        });

    }
    const DeleteFunc = async (idd) => {

        console.log("fsfsf", idd)


        swal({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            buttons: {
                cancel: 'No',
                confirm: 'Yes',
            },
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                // User clicked "Yes", perform the delete action
                // Add your delete logic here


                const token = localStorage.getItem("IDMS_TOKEN");
                try {
                    const Response = await axios({
                        method: "DELETE",
                        url: `${PARTNER_URL}/` + idd,
                        headers: {
                            Authorization: token
                        }
                    })


                    console.log("Response====>", Response.data)
                    fetchData2()
                    swal("Success!", "Partner Deleted Successfully!", "success")


                }

                catch (err) {
                    console.log("errr====>", err.response.data.errors)
                }



                // swal('Poof! Your file has been deleted!', {
                //     icon: 'success',
                // });
            } else {
                // User clicked "No" or closed the dialog
                swal('Your file is safe!', {
                    icon: 'info',
                });
            }
        });


    }
    const statusChangeFunc = async (partner_id, index) => {


        var data = {
            "method": "PUT",
            "route_name": "partner_statuses/" + partner_id

        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response.success====>", Response.success)


            var array = [...data1]
            array[index].status.name = (array[index].status.name != "Active" ? "Active" : 'In active')
            setData1(array)


            swal("Success!", "Partner Status updated successfully!", "success")

            setTimeout(() => {
                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error)

        }
    }



    const PageOnpresFunc = (pagedrop, value,) => {

        var post_data = {
            per_page: pagedrop,
            page: value,
            search: query.search,
            sort_by: "",
            sort_direction: query.sort_direction
        }
        console.log("value", value)

        setQuery(post_data)

        fetchData2(post_data);

    }


    const OnSearchFunc = (value) => {


        var post_data = {
            per_page: query.per_page,
            page: 1,
            search: value,
            sort_by: "",
            sort_direction: ''
        }
        console.log("value", value)
        setQuery(post_data)
        fetchData2(post_data);

    }
    return (
        <div className="d-flex profile">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>


                        {/*  */}

                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Partner Management</h4>
                        </div>
                        {/* {apiState && !apiState.loading ? */}
                            <div className="col-lg-12 ">
                                <section className="panel Orpage_box">

                                    <header class="Orvalidation_form">
                                        <h6 className="list_organ">List of Partner</h6>
                                        <Link type="button" className=" btn btn-default Organ_addbtn" to="/partners_add" ><i className="fa fa-plus-circle arrowkey"></i>Add Partner</Link>



                                    </header>

                                    <Search_PageDrop OnSearch={(value) => OnSearchFunc(value)} PageDropClick={(value) => PageOnpresFunc(value, 1)} />

                                    {/* <div className="Organ_dropdown1  ">
                                    <div className="Organ_dropdown">
                                        <select id="dropdown">
                                            <option value="1">10</option>
                                            <option value="2">20</option>
                                            <option value="3">30</option>
                                            <option value="4">40</option>
                                            <option value="5">50</option>
                                        </select>
                                        <h5 className="recordpage">records per page</h5>
                                    </div>
                                    <div className="Organ_dropdown">
                                        <h5 className="recordpage">Search</h5>
                                        <input type="text" className="search_width" />
                                    </div>
                                </div> */}




                                    <div className="table-colorOrgan mob-overflow" >
                                        <table>
                                            <thead >

                                                <tr className="tabledata">
                                                    <th className="color">S.No</th>
                                                    <th className="color"><i className="fas fa-sort padding_sort"></i>Name</th>
                                                    <th className="color"><i className="fas fa-sort padding_sort"></i>Company Name</th>
                                                    <th className="color"><i className="fas fa-sort padding_sort"></i>Email</th>
                                                    <th className="color"><i className="fas fa-sort padding_sort"></i>Status</th>
                                                    <th className="color"><i className="fas fa-sort padding_sort"></i>Current Status</th>
                                                    <th className="color"><i className="fas fa-sort padding_sort"></i>Action</th>
                                                </tr>
                                            </thead>
                                            {data1.length > 0 ?
                                                <tbody>
                                                    {data1.map((Partner, index) => (
                                                        <tr key={index} className="tabledata">
                                                            <td>{((query.page - 1) * 10 + 1) + index}</td>
                                                            <td style={{ color: 'blue' }}>{Partner.name}</td>
                                                            <td>{Partner.company_name}</td>
                                                            <td>{Partner.email}</td>
                                                            <td>
                                                                <span
                                                                    className={
                                                                        Partner.status.name === 'Active'
                                                                            ? 'green-status'
                                                                            : Partner.status.name === 'In active'
                                                                                ? 'red-status'
                                                                                : Partner.status.name === 'Pending'
                                                                                    ? 'yellow-status'
                                                                                    : ''
                                                                    }
                                                                >
                                                                    {Partner.status.name}
                                                                </span>
                                                            </td>

                                                            <td>
                                                                {/* {Partner.status.name === 'Active' ? (
                                                            <a href="">Click to Deactivate</a>
                                                        ) : Partner.status.name === 'In active' ? (
                                                            <a href="">Click to Activate</a>
                                                        ) : Partner.status.name === 'Pending' ? (
                                                            <a href="">Click to Process</a>
                                                        ) : (
                                                            <a href="">Click to Something Else</a>
                                                        )} */}


                                                                <Link onClick={() => statusChangeFunc(Partner.id, index)}  >{Partner.status.name === 'Active' ? "Click to Deactivate" : "Click to Activate"}</Link>

                                                            </td>
                                                            <td>
                                                                <div className="dropdown history_dropdown">
                                                                    <button className="dropdown_alert">
                                                                        <span className="">▼</span>
                                                                    </button>
                                                                    <div className="dropdown-content">
                                                                        {/* <Link onClick={() => EditFunc1(Partner.id)}> Edit</Link>
                                                                <Link onClick={() => ViewFunc1(Partner.id)}>View</Link> */}

                                                                        <Link to={`/partners_view/${Partner.id}`}  > View</Link>
                                                                        <Link to={`/partners_edit/${Partner.id}`}> Edit</Link>
                                                                        <Link onClick={() => DeleteFunc(Partner.id)}>Delete</Link>


                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                :
                                                <tbody>

                                                    <tr style={{ textAlign: 'center' }}>
                                                        <td colSpan="10" style={{ textAlign: 'center', verticalAlign: 'middle' }}>No Record Found</td>
                                                    </tr>
                                                </tbody>
                                            }
                                        </table>
                                    </div>

                                    <PaginationContent PageClick={(value) => PageOnpresFunc(query.per_page, value)} />


                                </section>

                            </div>
                            {/* :
                            < div className='spinner-styletop'>
                                <Spinner />
                            </div>
                        } */}


                    </div>
                </div>
            </div>
        </div >


    );
}