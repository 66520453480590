import React, { useEffect, useState } from "react";
import {
    CDBBtn,
    CDBProgress,
    CDBTable,
    CDBTableHeader,
    CDBTableBody,
    CDBContainer,
    CDBLink
} from "cdbreact";

import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import "../../Dashboard.css";
import Select from 'react-select';
import { PARTNER_URL } from "../../../constant";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom/cjs/react-router-dom";
import swal from "sweetalert";

const Stateoption = [

    { value: 'India', label: 'India' },

];

export const PartnerAdd = () => {

    // const [Statename, setStatename] = useState(null);

    const [selectedOption1, setSelectedOption1] = useState('');

    const [Soptions, setoptions] = useState([]);

    const navigate = useHistory()

    const [error, setError] = useState({});

    const [PartnerVal, setPartnerVal] = useState('');
    const [PartnerVal1, setPartnerVal1] = useState('');
    const [PartnerVal2, setPartnerVal2] = useState('');
    const [PartnerVal3, setPartnerVal3] = useState('');
    const [PartnerVal4, setPartnerVal4] = useState('');
    const [PartnerVal5, setPartnerVal5] = useState('');
    const [PartnerVal6, setPartnerVal6] = useState('');
    const [PartnerVal7, setPartnerVal7] = useState('');
    const [PartnerVal8, setPartnerVal8] = useState('');



    const handlePartner = (event) => {

        setPartnerVal(event.target.value);

        // console.log(event);


    };
    const handlePartner1 = (event) => {

        setPartnerVal1(event.target.value);
    };
    const handlePartner2 = (event) => {

        setPartnerVal2(event.target.value);
    };
    const handlePartner3 = (event) => {

        setPartnerVal3(event.target.value);
    };
    const handlePartner4 = (event) => {

        setPartnerVal4(event.target.value);
    };
    const handlePartner5 = (event) => {

        setPartnerVal5(event.target.value);
    };
    const handlePartner6 = (event) => {

        setPartnerVal6(event.target.value);
    };
    const handlePartner7 = (event) => {

        setPartnerVal7(event.target.value);
    };
    const handlePartner8 = (event) => {

        setPartnerVal8(event.target.value);
    };


    const onchangefun = (e) => {


        console.log("e.target.value", e)
        setSelectedOption1(e.value)


    };
    useEffect(() => {

        fetchData();

    }, []);


    const fetchData = async () => {
        const token = localStorage.getItem("IDMS_TOKEN");


        try {
            const Response = await axios({
                method: "get",
                url: `${PARTNER_URL}/create`,
                headers: {
                    Authorization: token
                }
            })


            console.log("Response====>", Response.data)


            const array = Response.data.states.map((e) => ({

                label: e.name,
                value: e.id

            }))


            setoptions(array)


        }

        catch (err) {
            console.log("errr====>", err.response.data.errors)
        }

    };

    const handleSubmit1 = async () => {


        const token = localStorage.getItem("IDMS_TOKEN")

        console.log("token====>", token)


        var body = {
            "first_name": PartnerVal,
            "last_name": PartnerVal1,
            "company_name": PartnerVal2,
            "mobile": PartnerVal3,
            "email": PartnerVal4,
            "notes": PartnerVal5,
            "fedid_ssn": PartnerVal6,
            "address": PartnerVal7,
            "city": PartnerVal8,
            "state_id": selectedOption1

        }


        try {
            const Response = await axios({
                method: "POST",
                url: `${PARTNER_URL}`,
                data: body,
                headers: {
                    Authorization: token
                }
            })


            console.log("Response_Admin====>", Response.data)

           
            swal("Success!", "Partner Added Successfully!", "success")

            setTimeout(() => {
                navigate.push("/partners")

                swal.close();
            }, 1500);

        }

        catch (err) {
            console.log("Admin_errr====>", err.response.data)
            setError(err.response.data.errors)

        }


    }


    return (
        <div className="dashboard d-flex">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>


                        {/*  */}

                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Partner Management</h4>
                        </div>

                        <div className="container-fluid">
                            <div className="row borderbox">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ" >Add Partner</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" to="/partners" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>

                                </header>
                                <div className="col-sm-12 col-md-6" style={{ marginTop: '18px' }}>




                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">First Name <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" value={PartnerVal}
                                                onChange={handlePartner} required />
                                            <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.first_name}</span>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Last Name <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" value={PartnerVal1}
                                                onChange={handlePartner1} required />
                                            <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.last_name}</span>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Company Name <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" value={PartnerVal2}
                                                onChange={handlePartner2} required />
                                            <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.company_name}</span>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Phone No <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" id="mobileNumber" value={PartnerVal3}
                                                onChange={handlePartner3} required />
                                            <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.mobile}</span>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Email ID <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="email" className="form-control validate[required,custom[phone]] " value={PartnerVal4}
                                                onChange={handlePartner4} required />
                                            <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.email}</span>
                                        </div>
                                    </div>



                                </div>


                                <div className="col-md-6" style={{ marginTop: '18px' }}>


                                    <div className="form-group form-textSetting">
                                        <label for="inputPassword3" className="col-sm-3 control-label">FEDID/SSN</label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control inputbox-height validate[required]" value={PartnerVal6}
                                                onChange={handlePartner6} />
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="inputEmail3" class="col-sm-3 control-label">Address</label>
                                        <div className="col-sm-7">
                                            <textarea className="form-control inputbox-height validate[required] note-style" value={PartnerVal7}
                                                onChange={handlePartner7} ></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="inputPassword3" className="col-sm-3 control-label">City <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control inputbox-height validate[required]" value={PartnerVal8}
                                                onChange={handlePartner8} />
                                            <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.city}</span>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="inputPassword3" className="col-sm-3 control-label">State <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            
                                            <div className="">
                                                <Select
                                                    placeholder="[-Select State-]"
                                                    defaultValue={selectedOption1}
                                                    onChange={(e) => onchangefun(e)}
                                                    options={Soptions}
                                                />
                                            </div>
                                            <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.state_id}</span>
                                        </div>
                                       
                                    </div>

                                    





                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group form-textSetting">
                                        <label for="inputEmail3" class="col-md-2 control-label">Notes </label>
                                        <div className="col-sm-8">
                                            <textarea className="form-control inputbox-height validate[required] note-style"  value={PartnerVal5}
                                                onChange={handlePartner5} ></textarea>

                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <button type="button" onClick={() => handleSubmit1()} className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>






                        </div>



                        <div className="container-fluid background-color" >

                        </div>


























                    </div>
                </div>
            </div>







        </div >

    );
}
