import React, { useState } from "react";
import {
    CDBBtn,
    CDBProgress,
    CDBTable,
    CDBTableHeader,
    CDBTableBody,
    CDBContainer,
    CDBLink
} from "cdbreact";

import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import "./Dashboard.css";
import Select from 'react-select';

const Visacoption = [

    { value: 'Tamil Nadu', label: 'Tamil Nadu' },

];
const candiState = [

    { value: 'India', label: 'India' },

];
const Joboption = [

    { value: 'Full Time', label: 'Full Time' },
    { value: 'Part Time', label: 'Part Time' }

];
export const AcCandimgmtEdit = () => {

    const [canVisa, setcanVisa] = useState(null);
    const [candidateSate, setcandidateSate] = useState(null);
    const [Job, setJob] = useState(null);


    return (
        <div className="dashboard d-flex">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>


                        {/*  */}

                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Candidate Management</h4>
                        </div>

                        <div className="container-fluid">
                            <div className="row borderbox">
                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Edit Candidate</h6>
                                    <a type="button" className=" btn btn-default Organ_addbtn" href="/AccountCandidate" ><i className="fa fa-arrow-left arrowkey"></i>Back</a>

                                </header>


                                <div className="col-sm-12 col-lg-6" style={{ marginTop: '18px' }}>




                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">First Name <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" id="mobile"  name="data[User][mobile]" required />
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Last Name <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" id="mobile"  name="data[User][mobile]" required />
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Email <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" id="mobile"  name="data[User][mobile]" required />
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Contact No. <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" id="mobile"  name="data[User][mobile]" required />
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Date Of Birth</label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" id="mobile"  name="data[User][mobile]" required />
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Location<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" id="mobile"  name="data[User][mobile]" required />
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Address</label>
                                        <div className="col-sm-7">
                                            <textarea className="form-control inputbox-height validate[required]" id="address" cols="5" name="data[User][address]" ></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">City</label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" id="mobile"  name="data[User][mobile]" required />
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">State<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <div className="">
                                                <Select
                                                    placeholder="[-Select State"
                                                    defaultValue={candidateSate}
                                                    onChange={setcandidateSate}
                                                    options={candiState}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Zipcode</label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" id="mobile"  name="data[User][mobile]" required />
                                        </div>
                                    </div>

                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Employer <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <form className="checkbox_visa">
                                                <div className="form-check" >
                                                    <input className="form-check-input" type="radio" name="radioOptions" id="radioOption1" value="option1" />
                                                    <label className="form-check-label" for="radioOption1">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check checkmtop">
                                                    <input className="form-check-input" type="radio" name="radioOptions" id="radioOption2" value="option2" />
                                                    <label className="form-check-label" for="radioOption2">
                                                        No
                                                    </label>
                                                </div>

                                            </form>
                                        </div>

                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">1099 or W2<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <form className="checkbox_visa">
                                                <div className="form-check" >
                                                    <input className="form-check-input" type="radio" name="radioOptions" id="radioOption1" value="option1" />
                                                    <label className="form-check-label" for="radioOption1">
                                                        1099
                                                    </label>
                                                </div>
                                                <div className="form-check checkmtop">
                                                    <input className="form-check-input" type="radio" name="radioOptions" id="radioOption2" value="option2" />
                                                    <label className="form-check-label" for="radioOption2">
                                                        W2
                                                    </label>
                                                </div>

                                            </form>
                                        </div>

                                    </div>


                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Qualification<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" id="mobile"  name="data[User][mobile]" required />
                                        </div>
                                    </div>

                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Skills<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <textarea className="form-control inputbox-height validate[required]" id="address" cols="5" name="data[User][address]" ></textarea>
                                        </div>
                                    </div>

                                    



                                </div>
                                <div className="col-md-6" style={{ marginTop: '18px' }}>


                                   

                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Visa Type<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <div className="">
                                                <Select
                                                    placeholder="[-Select vis Type-]"
                                                    defaultValue={canVisa}
                                                    onChange={setcanVisa}
                                                    options={Visacoption}
                                                />
                                            </div>
                                        </div>
                                    </div>






                                    <div className="form-group form-textSetting">
                                        <label for="inputPassword3" className="col-sm-3 control-label">Visa Attachment<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="file" className="" />
                                            <i className="fas fa-download mt-3">Download</i>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Reason<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <textarea className="form-control inputbox-height validate[required]" id="address" cols="5" name="data[User][address]" ></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="inputPassword3" className="col-sm-3 control-label">Candidate Rate<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control inputbox-height validate[required]" />
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="inputPassword3" className="col-sm-3 control-label">Resume<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="file" className="" />
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Geographical Constraints <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <form className="checkbox_visa">
                                                <div className="form-check" >
                                                    <input className="form-check-input" type="radio" name="radioOptions" id="radioOption1" value="option1" />
                                                    <label className="form-check-label" for="radioOption1">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check checkmtop">
                                                    <input className="form-check-input" type="radio" name="radioOptions" id="radioOption2" value="option2" />
                                                    <label className="form-check-label" for="radioOption2">
                                                        No
                                                    </label>
                                                </div>

                                            </form>
                                        </div>

                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Barred <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <form className="checkbox_visa">
                                                <div className="form-check" >
                                                    <input className="form-check-input" type="radio" name="radioOptions" id="radioOption1" value="option1" />
                                                    <label className="form-check-label" for="radioOption1">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check checkmtop">
                                                    <input className="form-check-input" type="radio" name="radioOptions" id="radioOption2" value="option2" />
                                                    <label className="form-check-label" for="radioOption2">
                                                        No
                                                    </label>
                                                </div>

                                            </form>
                                        </div>

                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Description</label>
                                        <div className="col-sm-7">
                                            <textarea className="form-control inputbox-height validate[required]" id="address" cols="5" name="data[User][address]" ></textarea>
                                        </div>
                                    </div>

                                    <div className="form-group" >

                                        <div className=" col-sm-7" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <button type="button" className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>
                                        </div>
                                    </div>






                                </div>







                            </div>




                        </div>



                        <div className="container-fluid background-color" >

                        </div>


























                    </div>
                </div>
            </div>







        </div >

    );
}
