import React, { useState } from "react";
import { InfinitySpin, MutatingDots, RotatingLines } from "react-loader-spinner";
import { useApiResponseContext } from "../Config/API/ApiResponseContext";




export const Spinner = () => {

    const { apiState } = useApiResponseContext();

    console.log("responseStateaaaaaaaa", apiState)

    return (

        <div className="spinner-style">
            <RotatingLines
                strokeColor="#8f9fba"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
            />
        </div>
    )




}

