import React, { useState } from "react";
import "./Login.css"
import axios from "axios";
import { LOGIN_URL } from "../../constant";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import swal from "sweetalert";

// import { BrowserRouter, useNavigate } from "react-router-dom";



export const LoginContent = () => {


    const navigate = useHistory()

    const [error, setError] = useState({});

    const [inputVal, setinputVal] = useState('');



    const handleInputChange = (event) => {
        setinputVal(event.target.value);
    };
    const [inputVal1, setinputVal1] = useState('');

    const handleInputChange1 = (event) => {
        setinputVal1(event.target.value);
    };
    const handleSubmit = async (event) => {

        console.log(inputVal);
        console.log(inputVal1);


        // console.log("urllllllllll", `${LOGIN_URL}`);

        var body = {
            "user_name": inputVal,
            "password": inputVal1
        }


        try {
            const Responseee = await axios({
                method: "POST",
                url: `${LOGIN_URL}`,
                data: body
            })

            console.log("Responseee", Responseee.data)

            localStorage.setItem("IDMS_TOKEN", "Bearer " + Responseee.data.plainTextToken)
            localStorage.setItem("user_type", Responseee.data.accessToken.name)

            // swal("Success!", "Logged in Successfully!", "success")
            navigate.push("/profilesettings")

            // setTimeout(() => {
            //     swal.close();
            // }, 2000);




        }

        catch (err) {


            console.log("errr====>", err.response.data.errors)

            setError(err.response.data.errors)
        }


    }
    return (


        <div>


            <div className="clearfix altimaximg">

                <div className="container">
                    <div className="img_logoNew">
                        <img alt="panelImage" src="img/pane/logo.png" />

                    </div>

                    <div className="row">
                        <div className="col" style={{ padding: "10px" }}>
                            <div className=" login">
                                <h3 className="logins">LOGIN</h3>
                                {/* 
                                <form autoComplete="on">
                                    <div className="form-group">
                                        <label  for="username" className='email-text lab'>Email / Username</label>
                                        <input
                                         className='input-color form-control box' type="text" name="email" value={inputVal}
                                            onChange={handleInputChange} placeholder="Email / Username" required />

                                        <span style={{ color: "red", fontSize: 15 }}>{error.user_name}</span>

                                    </div>
                                    <div className="form-group">
                                        <label for="password" className='email-text lab' >Password</label>
                                        <input
                                            onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
                                            name="password"
                                            className=' input-color form-control box'
                                            value={inputVal1}
                                            onChange={handleInputChange1}
                                            type="password"
                                            placeholder="Password"
                                            required />

                                        <span style={{ color: "red", fontSize: 15 }}>{error.password}</span>

                                    </div>

                                    <a  className="btn btn-default submit" type="submit" onClick={handleSubmit}>Submit</a>
                                </form> */}


                                <form autoComplete="on">
                                    <div className="form-group">
                                        <label htmlFor="email" className="email-text lab">
                                            Email / Username
                                        </label>
                                        <input
                                            autoComplete="username"
                                            className="input-color form-control box"
                                            type="text"
                                            id="email"
                                            name="email"
                                            value={inputVal}
                                            onChange={handleInputChange}
                                            placeholder="Email / Username"
                                        // required
                                        />
                                        <span style={{ color: "red", fontSize: 15 }}>{error.user_name}</span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password" className="email-text lab">
                                            Password
                                        </label>
                                        <input
                                            autoComplete="current-password"
                                            onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
                                            type="password"
                                            id="password"
                                            name="password"
                                            className="input-color form-control box"
                                            value={inputVal1}
                                            onChange={handleInputChange1}
                                            placeholder="Password"
                                        // required
                                        />
                                        <span style={{ color: "red", fontSize: 15 }}>{error.password}</span>
                                    </div>
                                    <button className="btn btn-default submit" type="button" onClick={handleSubmit}>
                                        Submit
                                    </button>
                                </form>


                                <h4 className="forgetpass"><a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">Forgot Password ?</a></h4>



                            </div>
                        </div>
                        <div className="col">
                            <div className=" logininfo">
                                <ul className="list-unstyled details">
                                    <li >
                                        <i className="fa fa-check icon_check"></i>

                                        <span className='icon-space'>Enter Your corporate email.</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-check icon_check"></i>
                                        <span className='icon-space' > Enter Your password</span></li>
                                    <li>
                                        <i className="fa fa-check icon_check"></i>

                                        <span className='icon-space' >If you do not know your password,</span>

                                    </li>
                                    <li>
                                        <span className='icon-space1' >click the - "Forgot Password?"</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-check icon_check"></i>

                                        <span className='icon-space' > Will send you a temporary password to your  </span>
                                        <li>
                                            <span style={{ marginLeft: '27px' }}>email id.</span>
                                        </li>
                                    </li>
                                </ul>

                            </div>

                        </div>
                    </div>


                </div >





                <div className="footer">
                    <div className="row container">
                        <div className="col-md-3"  >
                            <img alt="panelImage" src="img/pane/late Icon.jpg" style={{ width: "50%", height: "auto", marginTop: "13px" }} />
                        </div>
                        <div className="col-md-5" >
                            <p className="footer-para">Copyrights <i class="fa fa-copyright" aria-hidden="true"></i>  ALTIMATIX. All Rights Reserved.</p>
                        </div>
                        <div className="col-md-4 socialicon-arrange">
                            <ul class=" socialicon">
                                <li ><a href="#"><i className="fab fa-facebook icon_facebook"></i></a></li>
                                <li><a href="#"><i className="fab fa-twitter icon_twitter"></i></a></li>
                                <li><a href="#"><i className="fab fa-linkedin icon_linkedin"></i></a></li>
                                <li><a href="#"><i className="fab fa-google icon_google"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>













            </div >


















        </div>
























    );
}
