import React, { useState } from "react";
import { InfinitySpin, MutatingDots, RotatingLines } from "react-loader-spinner";
import { ApiCall } from "../Config/API";
import swal from "sweetalert";
import Modal from 'react-bootstrap/Modal';




export const RequirementNoteModal = (props) => {

    const [notesmodal, setNotesmodal] = useState(true);
    const [notestext, setNotestext] = useState('');
    const [noteserror, setNoteserror] = useState({});
    console.log("props.req_id", props.req_id)

    const NotesSendApi = async () => {


        var data = {
            "method": "POST",
            "route_name": "requirements/" + props.req_id + "/requirement_notes",
            "body": {
                'body': notestext
            }
        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response", Response.success)

            props.closemodal(Response.success)

            swal("Success!", "Your notes added successfully.!", "success")
            setNotesmodal(false)
            setNotestext('')
            setNoteserror({})
            setTimeout(() => {
                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error.errors)
            setNoteserror(Response.error.errors)
        }
    }




    return (

        <Modal
            show={notesmodal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => (setNotesmodal(false), props.closemodal(true))}
        >
            <Modal.Header className="modal-header" closeButton >
                <Modal.Title id="contained-modal-title-vcenter">
                    Requirement Notes
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <textarea
                    placeholder="Enter your notes..."
                    onChange={(e) => setNotestext(e.target.value)}
                    className="form-control inputbox-height validate[required] note-style" required id="address" cols="5" name="data[User][address]" >

                </textarea>
                {noteserror.body ?
                    <span style={{ marginTop: 10 }} className="span-error" >{noteserror.body}</span>
                    : null}
                {/* <span className="span-error" >(Maximum 250 characters allowed)</span> */}
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => NotesSendApi()} type="button" className="btn btn-default Organ_addbtn">Post Notes</button>

                {/* <Button >Close</Button> */}
            </Modal.Footer>
        </Modal>
    )




}

