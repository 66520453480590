import React, { useEffect, useState } from "react";
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import Select from 'react-select';
import { Link } from "react-router-dom/cjs/react-router-dom";
import { ApiCall } from "../../../Config/API";
import swal from "sweetalert";
import { RequirementNoteModal } from "../../../Components/RequirementNoteModal";
import { PaginationContent } from "../../../Components/PaginationContent";
import { Search_PageDrop } from "../../../Components/Search_PageDrop";
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";
// import Search from 'react-select';

const Headoption = [

    { value: 'Robert', label: 'Robert' },

];


export const Head_MyRequirementList = () => {

    const [Headreq, setHeadreq] = useState([]);

    const [Headoption, setHeadoption] = useState([]);
    const [RequirementList, setRequirementList] = useState([]);
    const [Assignnedlist, setAssignnedlist] = useState([]);
    const [HeadSelected, setHeadSelected] = useState([]);


    const [requirement_id, setRequirement_id] = useState('');
    const [notesmodal, setNotesmodal] = useState(false);

    const [query, setQuery] = useState({
        per_page: 10,
        page: 1,
        search: '',
        sort_by: "",
        sort_direction: ''

    });

    useEffect(() => {


        GetApi(query);
    }, []);
    const { dispatch } = useApiResponseContext();

    const GetApi = async (query) => {
        var data = {
            "method": "get",
            "route_name": "requirement_self_assigns?",
            'query': query,
        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response", Response.success)

            dispatch({ type: 'SUCCESS', payload: Response.success.requirements });

            setRequirementList(Response.success.requirements.data)

            var array = Response.success.recruiters.map((e) => ({

                id: e.id,
                label: e.name,
                selected: false
            }))



            setHeadreq(array)


        }
        else {
            console.log("Error", Response.error)

        }
    };
    const AssignHeadrecruiter = async () => {

        const bodydata = {
            "recruiter_ids": HeadSelected,
            "requirement_ids": Assignnedlist
        }



        var data = {
            "method": "POST",
            "route_name": "requirement_assigns",
            'body': bodydata
        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response", Response.success)
            GetApi(query)
            swal("Success!", "Requirement Assigned to Recruiter Sucessfully!", "success")

            setTimeout(() => {
                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error)

        }



    }

    const toggleOption = (id) => {

        const data = Headreq.map((option) => {
            if (option.id === id) {
                return { ...option, selected: !option.selected };
            }
            return option;
        })

        // console.log("data", data)

        const filteredData = data
            .filter(item => item.selected === true)
            .map(item => item.id);

        console.log("fhfh", filteredData);
        setHeadSelected(filteredData)
        setHeadreq(data);

    };

    const selectOption = async (id, e) => {

        const data = [...Assignnedlist]

        // Check if the ID is in the array
        if (data.includes(id)) {
            var ssidArray = Assignnedlist.filter(item => item !== id);
            // console.log(ssidArray);
            setAssignnedlist(ssidArray)

        } else {
            data.push(id)

            // console.log("data", data)

            setAssignnedlist(data)


        }



    }

    const PageOnpresFunc = (pagedrop, value,) => {

        var post_data = {
            per_page: pagedrop,
            page: value,
            search: query.search,
            sort_by: "",
            sort_direction: query.sort_direction
        }
        console.log("value", value)

        setQuery(post_data)

        GetApi(post_data);

    }


    const OnSearchFunc = (value) => {


        var post_data = {
            per_page: query.per_page,
            page: 1,
            search: value,
            sort_by: "",
            sort_direction: ''
        }
        console.log("value", value)
        setQuery(post_data)
        GetApi(post_data);

    }


    return (
        <div className="d-flex">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}


                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Requirement Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">List of My Requirement</h6>
                                    <div>
                                        <Link type="button" className=" btn btn-default Organ_addbtn" to="/head_requirements" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>

                                    </div>
                                </header>
                                <div style={{ margin: '15px' }}>
                                    <div className=" form-textSetting">
                                        <label for="inputPassword3" className="col-sm-1.5 control-label">Assign to Recruiter</label>
                                        <div className="col-sm-2 custom-124">



                                            <div className="custom-dropdown">
                                                <button className="req-style">Select</button>
                                                <div className="dropdown-content">
                                                    {Headreq.map((option) => (
                                                        <label key={option.id}>
                                                            <input
                                                                type="checkbox"
                                                                checked={option.selected}
                                                                onChange={() => toggleOption(option.id)}
                                                            />
                                                            {option.label}
                                                        </label>
                                                    ))}
                                                </div>
                                            </div>


                                        </div>


                                        {HeadSelected.length > 0 && Assignnedlist.length > 0 ?

                                            <div onClick={() => AssignHeadrecruiter()} className="btn btn-default assign_addbtn col-sm-1"  >Assign</div>

                                            : null}
                                    </div>
                                </div>

                                <Search_PageDrop OnSearch={(value) => OnSearchFunc(value)} PageDropClick={(value) => PageOnpresFunc(value, 1)} />



                                <div className="table-colorOrgan mob-overflow">
                                    <table >
                                        <thead >
                                            <tr>
                                                <th className="color">S.No</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Requirement No.</th>
                                                <th className="color">Requirement Title</th>
                                                <th className="color">Location</th>
                                                <th className="color">No.Candidate</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Action</th>
                                                <th className="color">Candidate</th>
                                                <th className="color">Assign</th>
                                                <th className="color">Notes</th>
                                            </tr>
                                        </thead>

                                        {RequirementList.length > 0 ?
                                            <tbody>
                                                {RequirementList.map((e, i) => (
                                                    <tr className="tabledata">
                                                       <td>{((query.page - 1) * 10 + 1) + i}</td>
                                                        <td style={{ color: 'blue' }}>{e.requirement_no}</td>
                                                        <td>{e.title}</td>
                                                        <td>{e.location}</td>
                                                        <td>({e.candidates_count})</td>
                                                        <td>
                                                            <div className="dropdown history_dropdown">
                                                                <button className="dropdown_alert">
                                                                    <span className="">▼</span>
                                                                </button>
                                                                <div className="dropdown-content">

                                                                    <Link to={`/head_myrequirement_view/${e.id}`}  > View</Link>

                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td >
                                                            <Link to={`/head_selectCandidate_add/${e.id}`}  >Select</Link>


                                                        </td>

                                                        <td>
                                                            <input type="checkbox" onChange={() => selectOption(e.id)} />

                                                        </td>
                                                        <td>
                                                            {/* <img alt="panelImage" src="img/pane/notes.jpg" className='logoimgnav2' /> */}

                                                            <Link onClick={() => (setNotesmodal(true), setRequirement_id(e.id))}>
                                                                <img alt="panelImage" src="img/pane/notes.jpg" className='logoimgnav2' />
                                                            </Link>
                                                        </td>
                                                    </tr>

                                                ))}
                                            </tbody>

                                            :
                                            <tbody>

                                                <tr style={{ textAlign: 'center' }}>
                                                    <td colSpan="10" style={{ textAlign: 'center', verticalAlign: 'middle' }}>No Record Found</td>
                                                </tr>
                                            </tbody>
                                        }
                                    </table>
                                </div>

                                <PaginationContent PageClick={(value) => PageOnpresFunc(query.per_page, value)} />



                            </section>

                        </div>






                        {notesmodal ?
                            <RequirementNoteModal req_id={requirement_id} closemodal={(value) => value && setNotesmodal(false)} />
                            : null}















                    </div>
                </div>
            </div>
        </div>










    );
}

