import React, { useEffect, useState } from "react";
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import { STAFF_URL } from "../../../constant";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from 'react-router-dom';
import { ApiCall } from "../../../Config/API";
import swal from "sweetalert";
import { Search_PageDrop } from "../../../Components/Search_PageDrop";
import { PaginationContent } from "../../../Components/PaginationContent";
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";

export const StaffList = () => {

    const navigate = useHistory()


    const [data1, setData1] = useState([]);
    const [query, setQuery] = useState({
        per_page: 10,
        page: 1,
        search: '',
        sort_by: "",
        sort_direction: ''

    });


    useEffect(() => {


        fetchData2(query);
    }, []);

    const { dispatch } = useApiResponseContext();

    const fetchData2 = async (query) => {

        console.log("query", query)
        var data = {
            "method": "get",
            "route_name": `staffs?`,
            'query': query

        }
        const Response = await ApiCall(data)
        if (Response.success) {

            console.log("Response", Response.success)
            setData1(Response.success.data)



            dispatch({ type: 'SUCCESS', payload: Response.success });

        }
        else {
            console.log("Error", Response.error.errors)
            dispatch({ type: 'ERROR', payload: Response.error });
        }

    };




    const EditFunc1 = async (idd) => {

        console.log("fsfsf", idd)

        // navigate.push(`/OrganizationEdit/${idd}`);

        navigate.push('/staffs_edit', {
            state: {
                "id": idd,
            }
        });

    }

    const ViewFunc1 = async (idd) => {

        console.log("fsfsf", idd)

        // navigate.push(`/OrganizationEdit/${idd}`);

        navigate.push('/staffs_view', {
            state: {
                "id": idd,
            }
        });

    }
    const statusChangeFunc = async (staff_id, index) => {


        var data = {
            "method": "PUT",
            "route_name": "staff_statuses/" + staff_id

        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response.success====>", Response.success)


            var array = [...data1]
            array[index].status.name = (array[index].status.name != "Active" ? "Active" : 'In active')
            setData1(array)


            swal("Success!", "Staff Status updated successfully!", "success")

            setTimeout(() => {
                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error)

        }


    }

    const PageOnpresFunc = (pagedrop, value,) => {

        var post_data = {
            per_page: pagedrop,
            page: value,
            search: query.search,
            sort_by: "",
            sort_direction: query.sort_direction
        }
        console.log("value", value)

        setQuery(post_data)

        fetchData2(post_data);

    }


    const OnSearchFunc = (value) => {


        var post_data = {
            per_page: query.per_page,
            page: 1,
            search: value,
            sort_by: "",
            sort_direction: ''
        }
        console.log("value", value)
        setQuery(post_data)
        fetchData2(post_data);

    }



    return (
        <div className="d-flex">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}


                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Staff Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">List of Staff</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" to="/staffs_add" ><i className="fa fa-plus-circle arrowkey"></i>Add staff</Link>






                                </header>


                                <Search_PageDrop OnSearch={(value) => OnSearchFunc(value)} PageDropClick={(value) => PageOnpresFunc(value, 1)} />





                                <div className="table-colorOrgan mob-overflow" >
                                    <table>
                                        <thead >
                                            <tr>
                                                <th className="color">S.No</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Name</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>User Type</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Email</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Status</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Current Status</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Action</th>
                                            </tr>
                                        </thead>
                                        {data1.length > 0 ?
                                            <tbody>
                                                {data1.map((Staff, index) => (
                                                    <tr key={index} className="tabledata">
                                                        <td>{((query.page - 1) * 10 + 1) + index}</td>
                                                        <td style={{ color: 'blue' }}>{Staff.name}</td>
                                                        <td>{Staff.user_type.type}</td>
                                                        <td>{Staff.email}</td>

                                                        <td>
                                                            <span
                                                                className={
                                                                    Staff.status.name === 'Active'
                                                                        ? 'green-status'
                                                                        : Staff.status.name === 'In active'
                                                                            ? 'red-status'
                                                                            : Staff.status.name === 'Pending'
                                                                                ? 'yellow-status'
                                                                                : ''
                                                                }
                                                            >
                                                                {Staff.status.name}
                                                            </span>
                                                        </td>

                                                        <td>
                                                            {/* {Staff.status.name === 'Active' ? (
                                                            <a href="">Click to Deactivate</a>
                                                        ) : Staff.status.name === 'In active' ? (
                                                            <a href="">Click to Activate</a>
                                                        ) : Staff.status.name === 'Pending' ? (
                                                            <a href="">Click to Process</a>
                                                        ) : (
                                                            <a href="">Click to Something Else</a>
                                                        )} */}


                                                            <Link onClick={() => statusChangeFunc(Staff.id, index)}  >{Staff.status.name === 'Active' ? "Click to Deactivate" : "Click to Activate"}</Link>

                                                        </td>

                                                        <td>
                                                            <div className="dropdown history_dropdown">
                                                                <button className="dropdown_alert">
                                                                    <span className="">▼</span>
                                                                </button>
                                                                <div className="dropdown-content">
                                                                    {/* <a onClick={() => EditFunc1(Staff.id)}> Edit</a>
                                                                <a onClick={() => ViewFunc1(Staff.id)}>View</a> */}

                                                                    <Link to={`/staffs_view/${Staff.id}`}  > View</Link>
                                                                    <Link to={`/staffs_edit/${Staff.id}`}  > Edit</Link>


                                                                    {/* <a href="#">Mail</a>
                                                                <a href="#">Reset pwd</a> */}


                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>


                                                )

                                                )}
                                            </tbody>
                                            :
                                            <tbody>

                                                <tr style={{ textAlign: 'center' }}>
                                                    <td colSpan="10" style={{ textAlign: 'center', verticalAlign: 'middle' }}>No Record Found</td>
                                                </tr>
                                            </tbody>
                                        }
                                    </table>
                                </div>

                                <PaginationContent PageClick={(value) => PageOnpresFunc(query.per_page, value)} />



                            </section>

                        </div>





















                    </div>
                </div>
            </div>
        </div>










    );
}

