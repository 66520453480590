import React, { useEffect, useState } from "react";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import {
    CDBNavbar,
    CDBNavBrand,
    CDBNavbarNav,
    CDBNavToggle,
    CDBNavItem,
    CDBNavLink,
    CDBBtn,
    CDBCollapse
} from "cdbreact";
import '../../Profile.css';
import { Link, useParams, useHistory } from "react-router-dom/cjs/react-router-dom";
import { ApiCall } from "../../../Config/API";
import moment from "moment";
import { saveAs } from 'file-saver';
import swal from "sweetalert";

// Admin page here as hero

export const History_Req_Cand_View = ({ location }) => {

    const navigate = useHistory()



    const { cand_id, req_id } = useParams();

    const [candidatedetails, setCandidatedetails] = useState({});
    const [partnershort_detail, setPartnershort_detail] = useState({});

    const [state, setstate] = useState({});
    // const [creator, setCreator] = useState({});
    const [user_type, setuser_type] = useState({});
    const [employer_rate_type, setemployer_rate_type] = useState({});
    const [visatype, setvisatype] = useState({});
    const [fillabledata, setFillabledata] = useState({})
    const [Visatype, setVisatype] = useState({})
    const [Jobtype, setJobtype] = useState({})
    const [req_noteslist, setReq_noteslist] = useState([])

    const { data } = location.state || {};

    useEffect(() => {


        // GetApi()
        getAll()
    }, [])


    const getAll = async () => {


        var data = {
            "method": "get",
            "route_name": "requirements/" + req_id + "/candidates/" + cand_id,

        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response.success====>", Response.success)
            setFillabledata(Response.success.requirement)
            setVisatype(Response.success.visa)
            setJobtype(Response.success.job_type)
            setCandidatedetails(Response.success.candidate)

            setReq_noteslist(Response.success.requirement_notes)
            setPartnershort_detail(Response.success.short_list)

        }
        else {
            console.log("Error", Response.error)

        }




    };

    const GetApi = async () => {



        var data = {
            "method": "GET",
            "route_name": "candidates/" + cand_id,

        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response", Response.success)
            setCandidatedetails(Response.success)

            setstate(Response.success.state)


            // setCreator(Response.success.creator)

            setuser_type(Response.success.user_type)

            setemployer_rate_type(Response.success.employer_rate_type)
            setvisatype(Response.success.visa)
        }
        else {
            console.log("Error", Response.error)

        }

    }

    const PdfDownload = async (id, type) => {

        var data = {
            "method": "GET",
            "route_name": "candidates/" + id + '/files/' + type,
            "res_type": 'blob'
        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response", Response.success)
            saveAs(Response.success, 'downloaded_resume.pdf');


        }
        else {
            console.log("Error", Response.error)

        }

    }

    const DeleteFunc = async (id) => {


        var data = {
            "method": "DELETE",
            "route_name": "requirements/" + req_id + "/requirement_notes/" + id,

        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response.success====>", Response.success)

            getAll()
            swal("Success!", "Notes Deleted Successfully!", "success")

            setTimeout(() => {
                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error)

        }




    };


    return (

        <div className="d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}



                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Candidate Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Requirement Details</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn"
                                        onClick={() => navigate.goBack()}
                                    ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>



                                </header>
                                <div className="row Organization_sub">
                                    <div className="col-sm-12 col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Requirement No.</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.requirement_no}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Requirement Title</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.title}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Job Type</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.job_type}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Job Location</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.location}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Recruiter Rate (1099)</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                $ {fillabledata.recruiter_price_c2c_from} Per Hour -  $ {fillabledata.recruiter_price_c2c_to} Per Hour
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Recruiter Rate (W2)</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                $ {fillabledata.recruiter_price_w2_from} Per Hour -  $ {fillabledata.recruiter_price_w2_to} Per Hour
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Description</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.description}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Note</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.notes}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-6">

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Visa Restrictions</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.visa_name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Local Candidate Only</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.is_local_candidate_only == 0 ? "NO" : "YES"}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Contract Length</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.contract_length}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">No of Positions</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.position}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Client Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.client_name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Created on</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                {moment(fillabledata.created_at).format('MM-DD-YYYY hh:mm A')}

                                            </div>
                                        </div>



                                    </div>

                                </div>




                            </section>

                        </div><br></br>




                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Candidate Detail</h6>


                                </header>
                                <div className="row Organization_sub">
                                    <div className="col-sm-12 col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Candidate ID</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.candidate_id ? candidatedetails.candidate_id : "-"}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Email ID</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.email}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Contact No.</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.mobile}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Date of Birth</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.dob}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Location</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.location}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Address</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.address ? candidatedetails.address : "-"}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">City</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.city ? candidatedetails.city : "-"}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">State</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.state ? candidatedetails.state : "-"}


                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Zipcode</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.zipcode}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Geographical Constraints</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.is_geographical == 1 ? 'Yes' : "No"}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Barred</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.is_barred == 1 ? 'Yes' : "No"}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Description</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.description ? candidatedetails.description : "-"}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Created By</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails?.created_by?.name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">User Type</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails?.created_by?.user_type}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Created On</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {/* {candidatedetails.created_at} */}
                                                {moment(candidatedetails.created_at).format('MM-DD-YYYY hh:mm A')}

                                            </div>
                                        </div>



                                    </div>

                                    <div className="col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Employer</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                <span>
                                                    {candidatedetails.is_employer == 1 ? 'Yes' : "No"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Employer Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.employer_name ? candidatedetails.employer_name : "-"}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">1099 or W2</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.employer_rate_type ? candidatedetails.employer_rate_type : "-"}
                                            </div>
                                        </div>


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Qualification</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.qualification}

                                            </div>
                                        </div>


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Skills</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.skills}

                                            </div>
                                        </div>


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Visa Type</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.visa_name ? candidatedetails.visa_name : "-"}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Visa Attachment</label>
                                            {/* <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                <i className="fas fa-download"> Download</i>
                                            </div> */}

                                            <Link onClick={() => PdfDownload(cand_id, "visa_attachment")}>
                                                <i className="fas fa-download"> Download</i>
                                            </Link>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Reason</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.reason}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Candidate Rate</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                $ {candidatedetails.candidate_rate}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Resume</label>
                                            {/* <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                <i className="fas fa-download"> Download</i>
                                            </div> */}

                                            <Link onClick={() => PdfDownload(cand_id, "resume")}>
                                                <i className="fas fa-download"> Download</i>
                                            </Link>
                                        </div>







                                    </div>

                                </div>




                            </section>

                        </div>


                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Partner Shortlist Detail</h6>




                                </header>
                                <div className="row Organization_sub">
                                    <div className="col-sm-12 col-md-6">

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Interview Date</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {partnershort_detail?.interview_at ?
                                                    partnershort_detail.interview_at
                                                    :
                                                    "N/A"
                                                }
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Interview Status</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {partnershort_detail?.interview_status ? partnershort_detail.interview_status : "Waiting for interview time"}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Round</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {partnershort_detail?.rounds_count ? partnershort_detail.rounds_count : "N/A"}
                                            </div>
                                        </div>


                                    </div>

                                    <div className="col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Partner Status</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {partnershort_detail?.partner_status ? partnershort_detail.partner_status
                                                    :
                                                    <span >N/A</span>
                                                }
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Candidate Accept</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {partnershort_detail?.is_accepted_by_candidate == 1 ? "Yes" : partnershort_detail?.is_accepted_by_candidate == 0 ? "No" : "N/A"}
                                            </div>
                                        </div>



                                    </div>

                                </div>




                            </section>

                        </div>







                        {req_noteslist.length > 0 ?
                            <div className="col-lg-12 ">
                                <section className="panel Orpage_box">

                                    <header class="Orvalidation_form">
                                        <h6 className="list_organ">View Requirement Notes</h6>

                                    </header>

                                    <div className="notes_scroll">

                                        {req_noteslist.map((e, i) => (



                                            <div className="req_notesview row" >

                                                <div className="col-sm-1" style={{ fontWeight: '300' }}>
                                                    {i + 1}.
                                                </div>
                                                <div className="col-sm-9" >

                                                    <div style={{ fontWeight: '300', fontStyle: "initial" }} >
                                                        {e.body}
                                                    </div>

                                                    <div style={{ fontWeight: '300', fontStyle: "italic", marginTop: 8 }} >
                                                        {e.created_by?.name} - {e.created_by?.user_type}
                                                    </div>

                                                </div>

                                                <div className="col-sm-2 reqnote_time" >
                                                    {moment(e.created_at).format('MM-DD-YYYY hh:mm A')}
                                                </div>
                                                {e.is_can_delete ?
                                                    <div onClick={() => DeleteFunc(e.id)} className="close-icon-container">
                                                        <img alt="panelImage" src="../../img/pane/cross.png" className='logo_notes' />
                                                    </div>
                                                    : null}
                                            </div>




                                        ))
                                        }
                                    </div>
                                </section>
                            </div>

                            : null}



                    </div>
                </div>
            </div>
        </div>





    );
}
