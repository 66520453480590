// import React from 'react';
// import Routes from './Routes';
// import './App.css';

// import Sidebar from './Sidebar';
// import Navbar from './Navbar';
// import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';


// function App() {

//   return (
//     // <div>
//       <Router>

//         <div className="dashboard d-flex">
//           <div>
//             
//           </div>
//           <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
//             

//             <p>djfjdfjdjf</p>

//           </div>
//         </div>
//         {/* <Routes /> */}

//       </Router>

//     // </div>
//   );
// }

// export default App;

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import './App.css';
import { CDBNavbar } from 'cdbreact';
import { Header } from './Navbar.style';
import './pages/allstyle.css'
import Routes from './Routes';
import { LoginContent } from './pages/Login/Login';
import { Redirect, Switch } from 'react-router-dom/cjs/react-router-dom';
import { Spinner } from './Components/Spinner';
import { MainScreen } from './Components/MainScreen';



const OtherRoutes = () => {


  const [loader, setLoader] = useState(false)

  // const { responseState } = useLoaderReducer();

  // console.log("responseState", responseState)
// 
  return (
    <div className="dashboard d-flex">
      <div>
        <Sidebar />
      </div>
      <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
        <Navbar />
        {loader ?
          <div className='spinner-styletop'>
            <Spinner />
          </div>
          :
          <Routes />
        }

      </div>
    </div>
  )
}

const token = localStorage.getItem("IDMS_TOKEN");

const App = () => {



  return (
    <Router>
      <Switch>
        <Route exact path="/" component={(routeProps) => (token ? <Redirect to="profilesettings" {...routeProps} /> : <Redirect to="login" {...routeProps} />)} />

        <Route path="/login" exact component={LoginContent} />
        <Route path="*" component={MainScreen} />

      </Switch>
    </Router>
  )


}

export default App;

