import React, { useEffect, useState } from "react";
import {
    CDBBtn,
    CDBProgress,
    CDBTable,
    CDBTableHeader,
    CDBTableBody,
    CDBContainer,
    CDBLink
} from "cdbreact";

import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import "../../Dashboard.css";
import Select from 'react-select';
import axios from "axios";
import { ADD_REQUIREMENT_URL, REQUIREMENT_URL } from "../../../constant";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom";
import { ApiCall } from "../../../Config/API";



export const Jobhistory_Edit = () => {


    const location = useLocation();



    const { id } = useParams()

    const Requirement_id = id



    const [rate, setRate] = useState([]);
    const [VisaType, setVisaType] = useState([]);

    const [Partner1, setPartner1] = useState([]);
    const [Job1, setJob1] = useState([]);


    const [error, setError] = useState({});

    const [fillabledata, setFillabledata] = useState(
        {
            "client_name": "",
            "partner_id": '',
            "title": "",
            "location": "",
            "job_type_id": '',
            "requirement_rate_type_id": '',
            "partner_price": '',
            "visa_id": '',
            "is_local_candidate_only": '',
            "contract_length": '',
            "position": '',
            "description": "sfsf",
            "recruiter_price_c2c_from": '',
            "recruiter_price_c2c_to": '',
            "recruiter_price_w2_from": '',
            "recruiter_price_w2_to": '',
            'notes': ''
        }
    );

    const handleInputChange = (fieldName, e) => {

        console.log("e", e)



        if (fieldName == "partner_id") {
            setFillabledata((prevData) => ({
                ...prevData,
                [fieldName]: e.value,
            }));
        }


        else if (fieldName == "job_type_id") {
            setFillabledata((prevData) => ({
                ...prevData,
                [fieldName]: e.value,
            }));
        }

        else if (fieldName == "visa_id") {
            setFillabledata((prevData) => ({
                ...prevData,
                [fieldName]: e.value,
            }));
        }
        else if (fieldName == "is_local_candidate_only") {
            setFillabledata((prevData) => ({
                ...prevData,
                [fieldName]: e,
            }));
        }
        else {

            setFillabledata((prevData) => ({
                ...prevData,
                [fieldName]: e.target.value,
            }));
        }

    };






    const [RequirementList, setRequirementList] = useState([]);


    const [Headoption, setHeadoption] = useState([]);


    useEffect(() => {


        getAll();
    }, []);

    const navigate = useHistory()
    const getAll = async () => {
        const token = localStorage.getItem("IDMS_TOKEN");

        var data = {
            "method": "GET",
            "route_name": "job_histories/" + Requirement_id + '/edit',

        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response", Response.success)
            setFillabledata(Response.success.requirement)

            var array = Response.success.partners.map((e) => ({

                value: e.id,
                label: e.name
            }))

            console.log("array====>", array)

            setPartner1(array)


            var array1 = Response.success.job_types.map((e) => ({

                value: e.id,
                label: e.type
            }))

            setJob1(array1)





            var array2 = Response.success.visa_restrictions.map((e) => ({

                value: e.id,
                label: e.name
            }))

            setVisaType(array2)

            setRate(Response.success.requirement_rate_types)

        }
        else {
            console.log("Error", Response.error)

        }





    };



    const SubmitFuc = async () => {

        const token = localStorage.getItem("IDMS_TOKEN");

        console.log("fillabledata", fillabledata)
        var data = {
            "method": "PUT",
            "route_name": "job_histories/" + Requirement_id,
            "body": fillabledata
        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response", Response.success)
            swal("Success!", "Requirement Updated Sucessfully!", "success")
            setTimeout(() => {
                navigate.push("/jobhistory")
                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error)

        }


        // try {
        //     const Response = await axios({
        //         method: "put",
        //         url: `${REQUIREMENT_URL}/` + Requirement_id,
        //         headers: {
        //             Authorization: token
        //         },
        //         data: fillabledata
        //     })

        //     console.log("ressss", Response.data)
        //     swal("Success!", "Requirement Updated Sucessfully!", "success")
        //     setTimeout(() => {
        //         navigate.push("/requirements")
        //         swal.close();
        //     }, 1500);
        // }
        // catch (err) {

        //     console.log("errr====>", err.response.data.errors)
        //     setError(err.response.data.errors)
        // }


    }


    const partner_id = Partner1.find(option => option.value === fillabledata.partner_id);
    const job_type_id = Job1.find(option => option.value === fillabledata.job_type_id);
    const visa_id = VisaType.find(option => option.value === fillabledata.visa_id);


    return (
        <div className="dashboard d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>


                        {/*  */}

                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Requirement Management</h4>
                        </div>

                        <div className="container-fluid">
                            <div className="row borderbox">
                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Edit Requirement</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" to="/jobhistory" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>

                                </header>


                                <div className="col-sm-12 col-lg-6" style={{ marginTop: '18px' }}>




                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-md-3  control-label">Client Name </label>
                                        <div className="col-md-7 ">
                                            <input type="text" className="form-control validate[required,custom[phone]]" id="mobile"
                                                name="client_name"
                                                value={fillabledata.client_name}
                                                onChange={(e) => handleInputChange("client_name", e)}
                                                required />


                                        </div>
                                    </div>

                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Partner Name<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <div className="mar-top">
                                                <Select
                                                    placeholder="[-Select Partner-]"
                                                    onChange={(e) => handleInputChange("partner_id", e)}
                                                    options={Partner1}
                                                    value={partner_id}
                                                />
                                            </div>
                                            {error.partner_id ?
                                                <span className="span-error"  >{error.partner_id}</span>
                                                : null}

                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Requirement Title<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]  mar-top" required
                                                name="title"
                                                value={fillabledata.title}
                                                onChange={(e) => handleInputChange("title", e)}

                                            />

                                            {error.title ?
                                                <span className="span-error"  >{error.title}</span>
                                                : null}

                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Job Location  <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]] mar-top " required
                                                name="location"
                                                value={fillabledata.location}
                                                onChange={(e) => handleInputChange("location", e)}


                                            />

                                            {error.location ?
                                                <span className="span-error"  >{error.location}</span>
                                                : null}
                                        </div>
                                    </div>

                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label ">Job Type<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <div className="mar-top">
                                                <Select
                                                    placeholder="[-Select Job Type-]"
                                                    value={job_type_id}
                                                    // onChange={setCitizen1}
                                                    onChange={(e) => handleInputChange("job_type_id", e)}
                                                    options={Job1}
                                                />
                                                {error.job_type_id ?
                                                    <span className="span-error"  >{error.job_type_id}</span>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Rate <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <form className="checkbox_visa mar-top">
                                                {rate.map((e, i) => (
                                                    <div className="form-check"
                                                    >
                                                        <input onChange={(value) => handleInputChange("requirement_rate_type_id", value)} className="form-check-input" type="radio"
                                                            checked={e.id == fillabledata.requirement_rate_type_id ? true : false}
                                                            value={e.id}

                                                        />


                                                        <label className="form-check-label" for="radioOption1">
                                                            {e.type}
                                                        </label>
                                                    </div>
                                                ))}
                                            </form>
                                            {error.requirement_rate_type_id ?
                                                <span className="span-error"  >{error.requirement_rate_type_id}</span>
                                                : null}
                                        </div>

                                    </div>

                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Partner Rate<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input name="partner_price"
                                                value={fillabledata.partner_price}
                                                onChange={(e) => handleInputChange("partner_price", e)} type="text" className="form-control validate[required,custom[phone]] mar-top" id="mobile" />
                                            {error.partner_price ?
                                                <span className="span-error"  >{error.partner_price}</span>
                                                : null}
                                        </div>
                                    </div>
                                    {/*For Desktop view  */}
                                    {/* <div className="Reqhide-mobview"> */}
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Recruiter Rate (1099)</label>
                                        <div className="col-sm-7" style={{ display: 'flex' }}>

                                            <div className="col-sm-5">
                                                <input name="recruiter_price_c2c_from"
                                                    value={fillabledata.recruiter_price_c2c_from}
                                                    onChange={(e) => handleInputChange("recruiter_price_c2c_from", e)} type="text" className="form-control validate[required,custom[phone]]" />

                                                {error.recruiter_price_c2c_from ?
                                                    <span className="span-error"  >{error.recruiter_price_c2c_from}</span>
                                                    : null}
                                            </div>
                                            <div className="col-sm-1">
                                                <label className="center-align">-</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input name="recruiter_price_c2c_to"
                                                    value={fillabledata.recruiter_price_c2c_to}
                                                    onChange={(e) => handleInputChange("recruiter_price_c2c_to", e)} type="text" className="form-control validate[required,custom[phone]]" />
                                                {error.recruiter_price_c2c_to ?
                                                    <span className="span-error"  >{error.recruiter_price_c2c_to}</span>
                                                    : null}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Recruiter Rate (W2)</label>
                                        <div className="col-sm-7" style={{ display: 'flex' }}>

                                            <div className="col-sm-5">
                                                <input name="recruiter_price_w2_from"
                                                    value={fillabledata.recruiter_price_w2_from}
                                                    onChange={(e) => handleInputChange("recruiter_price_w2_from", e)} type="text" className="form-control validate[required,custom[phone]]" />
                                                {error.recruiter_price_w2_from ?
                                                    <span className="span-error"  >{error.recruiter_price_w2_from}</span>
                                                    : null}
                                            </div>
                                            <div className="col-sm-1">
                                                <label className="center-align">-</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input name="recruiter_price_w2_to"
                                                    value={fillabledata.recruiter_price_w2_to}
                                                    onChange={(e) => handleInputChange("recruiter_price_w2_to", e)} type="text" className="form-control validate[required,custom[phone]]" />
                                                {error.recruiter_price_w2_to ?
                                                    <span className="span-error"  >{error.recruiter_price_w2_to}</span>
                                                    : null}
                                            </div>

                                        </div>
                                    </div>
                                    {/* </div> */}
                                    {/*  */}

                                    {/* For mobile view */}
                                    {/* <div className="Reqhide-desktop"> */}
                                    {/* <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Recruiter Rate (1099)<span className="required">*</span></label>
                                        <div className="col-sm-7" >

                                            <div className="col-sm-5">
                                                <input type="text" className="form-control validate[required,custom[phone]]"  />
                                            </div>
                                            <div className="col-sm-1">
                                                <label>-</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input type="text" className="form-control validate[required,custom[phone]]"  />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Recruiter Rate (W2)<span className="required">*</span></label>
                                        <div className="col-sm-7">

                                            <div className="col-sm-5">
                                                <input type="text" className="form-control validate[required,custom[phone]]"  />
                                            </div>
                                            <div className="col-sm-1">
                                                <label>-</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input type="text" className="form-control validate[required,custom[phone]]"  />
                                            </div>

                                        </div>
                                    </div> */}
                                    {/* </div> */}
                                    {/*  */}



                                </div>
                                <div className="col-md-6" style={{ marginTop: '18px' }}>

                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Visa Restriction<span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <div className="mar-top">
                                                <Select
                                                    placeholder="[-Select Visa Restriction-]"
                                                    value={visa_id}
                                                    onChange={(e) => handleInputChange("visa_id", e)}
                                                    options={VisaType}
                                                />
                                            </div>

                                            {error.visa_id ?
                                                <span className="span-error"  >{error.visa_id}</span>
                                                : null}
                                        </div>
                                    </div>


                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Local Candidate Only <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <form style={{ marginLeft: 15 }} className="checkbox_visa mar-top">
                                                <div className="form-check" >

                                                    <input onChange={(value) => handleInputChange("is_local_candidate_only", true)}
                                                        checked={fillabledata.is_local_candidate_only == 1 ? true : false}
                                                        className="form-check-input" type="radio" name="radioOptions" id="radioOption1" />
                                                    <label className="form-check-label" for="radioOption1">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check checkmtop">
                                                    <input

                                                        checked={fillabledata.is_local_candidate_only == 0 ? true : false}
                                                        onChange={(value) => handleInputChange("is_local_candidate_only", false)}
                                                        className="form-check-input" type="radio" name="radioOptions" id="radioOption2" />
                                                    <label className="form-check-label" for="radioOption2">
                                                        No
                                                    </label>
                                                </div>

                                            </form>

                                            {error.is_local_candidate_only ?
                                                <span className="span-error"  >{error.is_local_candidate_only}</span>
                                                : null}
                                        </div>

                                    </div>


                                    <div className="form-group form-textSetting">
                                        <label for="inputPassword3" className="col-sm-3 control-label">Contract Length </label>
                                        <div className="col-sm-7">
                                            <input name="contract_length"
                                                value={fillabledata.contract_length}
                                                onChange={(e) => handleInputChange("contract_length", e)} type="text" className="form-control inputbox-height validate[required]" id="city" />

                                            {error.contract_length ?
                                                <span className="span-error"  >{error.contract_length}</span>
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="inputPassword3" className="col-sm-3 control-label">No of Positions </label>
                                        <div className="col-sm-7">
                                            <input name="position"
                                                value={fillabledata.position}
                                                onChange={(e) => handleInputChange("position", e)} type="text" className="form-control inputbox-height validate[required]" id="state" />
                                            {error.position ?
                                                <span className="span-error"  >{error.position}</span>
                                                : null}
                                        </div>
                                    </div>







                                </div>

                                <div className="form-group form-textSetting">
                                    <label for="" className="col-sm-3 control-label">Description<span className="required">*</span></label>
                                    <div className="col-sm-7">
                                        <textarea
                                            value={fillabledata.description}
                                            onChange={(e) => handleInputChange("description", e)} className="note-style  form-control inputbox-height validate[required] texareaview" id="address" cols="5" name="data[User][address]" ></textarea>
                                    </div>
                                </div>

                                <div className="form-group form-textSetting">
                                    <label for="inputEmail3" class="col-sm-3 control-label">Notes</label>
                                    <div className="col-sm-7">
                                        <textarea
                                            value={fillabledata.notes}
                                            onChange={(e) => handleInputChange("notes", e)} className="note-style form-control inputbox-height validate[required] texareaview" id="address" cols="5" name="data[User][address]" ></textarea>
                                        <button onClick={() => SubmitFuc()} type="button" className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>

                                    </div>
                                </div>
                                {/* <div className="form-group">
                                    <div className=" col-sm-7">
                                    </div>
                                </div> */}


                            </div>






                        </div>



                        <div className="container-fluid background-color" >

                        </div>







                    </div>
                </div>
            </div>







        </div >

    );
}
