import React, { useEffect, useState } from "react";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import {
    CDBNavbar,
    CDBNavBrand,
    CDBNavbarNav,
    CDBNavToggle,
    CDBNavItem,
    CDBNavLink,
    CDBBtn,
    CDBCollapse
} from "cdbreact";
import '../Profile.css';
import { Link, useParams, useHistory } from "react-router-dom/cjs/react-router-dom";
import { ApiCall } from "../../Config/API";
import { saveAs } from 'file-saver';
import moment from "moment";

// Admin page here as hero

export const CandidateView = () => {

    const { id } = useParams();
    const navigate = useHistory()

    const [candidatedetails, setCandidatedetails] = useState({});
    const [state, setstate] = useState({});
    const [creator, setCreator] = useState({});
    const [user_type, setuser_type] = useState({});
    const [employer_rate_type, setemployer_rate_type] = useState({});
    const [visatype, setvisatype] = useState({});


    useEffect(() => {


        GetApi()

    }, [])

    const GetApi = async () => {



        var data = {
            "method": "GET",
            "route_name": "candidates/" + id,

        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response", Response.success)
            setCandidatedetails(Response.success)

            setstate(Response.success.state)


            setCreator(Response.success.created_by)

            setuser_type(Response.success.user_type)

            setemployer_rate_type(Response.success.employer_rate_type)
            setvisatype(Response.success.visa)
        }
        else {
            console.log("Error", Response.error)

        }

    }

    const PdfDownload = async (id, type) => {

        var data = {
            "method": "GET",
            "route_name": "candidates/" + id + '/files/' + type,
            "res_type": 'blob'
        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response", Response.success)
            saveAs(Response.success, 'downloaded_resume.pdf');


        }
        else {
            console.log("Error", Response.error)

        }

    }
    return (

        <div className="d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}



                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Candidate Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Candidate Detail</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn"
                                        //  to="/candidates" 
                                        onClick={() => navigate.goBack()}
                                    ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>



                                </header>
                                <div className="row Organization_sub">
                                    <div className="col-sm-12 col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Candidate ID</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.candidate_id ? candidatedetails.candidate_id : "-"}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Email ID</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.email}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Contact No.</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.mobile}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Date of Birth</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.dob}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Location</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.location}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Address</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.address ? candidatedetails.address : "-"}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">City</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.city ? candidatedetails.city : "-"}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">State</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.state ? candidatedetails.state : "-"}


                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Zipcode</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.zipcode}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Geographical Constraints</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.is_geographical == 1 ? 'Yes' : "No"}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Barred</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.is_barred == 1 ? 'Yes' : "No"}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Description</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.description ? candidatedetails.description : "-"}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Created By</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {creator.name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">User Type</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {creator.user_type}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Created On</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {moment(candidatedetails.created_at).format('MM-DD-YYYY hh:mm A')}
                                            </div>
                                        </div>



                                    </div>

                                    <div className="col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Employer</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                <span>
                                                    {candidatedetails.is_employer == 1 ? 'Yes' : "No"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Employer Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.employer_name ? candidatedetails.employer_name : "-"}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">1099 or W2</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                {candidatedetails.employer_rate_type != null ? candidatedetails.employer_rate_type : "-"}
                                            </div>
                                        </div>


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Qualification</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.qualification}

                                            </div>
                                        </div>


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Skills</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.skills}

                                            </div>
                                        </div>


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Visa Type</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.visa_name}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Visa Attachment</label>
                                            {/* <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                <i className="fas fa-download"> Download</i>
                                            </div> */}

                                            <Link onClick={() => PdfDownload(id, "visa_attachment")}>
                                                <i className="fas fa-download"> Download</i>
                                            </Link>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Reason</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.reason}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Candidate Rate</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                $ {candidatedetails.candidate_rate}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Resume</label>
                                            {/* <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                <i className="fas fa-download"> Download</i>
                                            </div> */}

                                            <Link onClick={() => PdfDownload(id, "resume")}>
                                                <i className="fas fa-download"> Download</i>
                                            </Link>
                                        </div>







                                    </div>

                                </div>




                            </section>

                        </div>



                    </div>
                </div>
            </div >
        </div >





    );
}
