import React, { useEffect, useState } from "react";
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { VISA_URL } from "../../../constant";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { ApiCall } from "../../../Config/API";
import swal from "sweetalert";
import { PaginationContent } from "../../../Components/PaginationContent";
import { Search_PageDrop } from "../../../Components/Search_PageDrop";
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";

export const VisaType_List = () => {


    const navigate = useHistory()


    const [visa1, setvisa1] = useState([]);
    const [query, setQuery] = useState({
        per_page: 10,
        page: 1,
        search: '',
        sort_by: "",
        sort_direction: ''

    });

    useEffect(() => {
        fetchData2(query);
    }, []);

    const { dispatch } = useApiResponseContext();

    const fetchData2 = async (query) => {

        console.log("query", query)
        var data = {
            "method": "get",
            "route_name": `visas?`,
            'query': query

        }
        const Response = await ApiCall(data)
        if (Response.success) {

            console.log("Response", Response.success)
            setvisa1(Response.success.data)



            dispatch({ type: 'SUCCESS', payload: Response.success });

        }
        else {
            console.log("Error", Response.error.errors)
            dispatch({ type: 'ERROR', payload: Response.error });
        }

    };




    const EditFunc1 = async (idd) => {

        console.log("fsfsf", idd)

        // navigate.push(`/OrganizationEdit/${idd}`);

        navigate.push('/visatype_edit', {
            state: {
                "id": idd,
            }
        });

    }



    const statusChangeFunc = async (visastype_id, index) => {


        var data = {
            "method": "PUT",
            "route_name": "visas_statuses/" + visastype_id

        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response.success====>", Response.success)


            var array = [...visa1]
            array[index].status.name = (array[index].status.name != "Active" ? "Active" : 'In active')
            setvisa1(array)


            swal("Success!", "Visatype Status updated successfully!", "success")

            setTimeout(() => {
                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error)

        }
    }


    const PageOnpresFunc = (pagedrop, value,) => {

        var post_data = {
            per_page: pagedrop,
            page: value,
            search: query.search,
            sort_by: "",
            sort_direction: query.sort_direction
        }
        console.log("value", value)

        setQuery(post_data)

        fetchData2(post_data);

    }


    const OnSearchFunc = (value) => {


        var post_data = {
            per_page: query.per_page,
            page: 1,
            search: value,
            sort_by: "",
            sort_direction: ''
        }
        console.log("value", value)
        setQuery(post_data)
        fetchData2(post_data);

    }
    return (
        <div className="d-flex">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}


                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Visa Type Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">List of Visa Type</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" to="/visatype_add" ><i className="fa fa-plus-circle arrowkey"></i>Add Visa Type</Link>




                                </header>


                                <Search_PageDrop OnSearch={(value) => OnSearchFunc(value)} PageDropClick={(value) => PageOnpresFunc(value, 1)} />





                                <div className="table-colorOrgan mob-overflow" >
                                    <table >
                                        <thead >
                                            <tr>
                                                <th className="color">S.No</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Name</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Type</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Status</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Current Status</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Action</th>
                                            </tr>
                                        </thead>
                                        {visa1.length > 0 ?
                                            <tbody>
                                                {visa1.map((visas, index) => (
                                                    <tr key={index} className="tabledata">
                                                        <td>{((query.page - 1) * 10 + 1) + index}</td>
                                                        <td>{visas.name}</td>
                                                        <td>{visas.visa_type.type}</td>
                                                        <td>
                                                            <span
                                                                className={
                                                                    visas.status.name === 'Active'
                                                                        ? 'green-status'
                                                                        : visas.status.name === 'In active'
                                                                            ? 'red-status'
                                                                            : visas.status.name === 'Pending'
                                                                                ? 'yellow-status'
                                                                                : ''
                                                                }
                                                            >
                                                                {visas.status.name}
                                                            </span>
                                                        </td>

                                                        <td>
                                                            {/* {visas.status.name === 'Active' ? (
                                                            <a href="">Click to Deactivate</a>
                                                        ) : visas.status.name === 'In active' ? (
                                                            <a href="">Click to Activate</a>
                                                        ) : visas.status.name === 'Pending' ? (
                                                            <a href="">Click to Process</a>
                                                        ) : (
                                                            <a href="">Click to Something Else</a>
                                                        )} */}

                                                            <Link onClick={() => statusChangeFunc(visas.id, index)}  >{visas.status.name === 'Active' ? "Click to Deactivate" : "Click to Activate"}</Link>

                                                        </td>
                                                        <td >
                                                            <div className="dropdown history_dropdown">
                                                                <button className="dropdown_alert">
                                                                    <span className="">▼</span>
                                                                </button>
                                                                <div className="dropdown-content">
                                                                    {/* <a onClick={() => EditFunc1(visas.id)}> Edit</a> */}
                                                                    <Link to={`/visatype_edit/${visas.id}`}  >Edit</Link>


                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            :
                                            <tbody>

                                                <tr style={{ textAlign: 'center' }}>
                                                    <td colSpan="10" style={{ textAlign: 'center', verticalAlign: 'middle' }}>No Record Found</td>
                                                </tr>
                                            </tbody>
                                        }
                                    </table>
                                </div>

                                <PaginationContent PageClick={(value) => PageOnpresFunc(query.per_page, value)} />



                            </section>

                        </div>





















                    </div>
                </div>
            </div>
        </div>










    );
}

