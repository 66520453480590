
import axios from 'axios';
import keyss from '../index';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useEffect } from 'react';
import { BASE_URL, URL_Export } from '../constants';





export const ApiCall = async (fetch_data, navigation) => {

    const token = localStorage.getItem("IDMS_TOKEN");



    const Res = {
        "success": null,
        "error": null
    }

    const GET_URL = await URL_Export()

    const RouteName = fetch_data.route_name;
    // Find the position of the question mark

    const endsWithQuestionMark = fetch_data.route_name.endsWith('?');

    // Use questionMarkIndex as needed
    console.log('fetch_data.cand_filter', fetch_data?.cand_filter)


    var normal = {
        per_page: 'per_page',
        page: 'page',
        search: 'search',
        sort_by: 'sort_by',
        sort_direction: 'sort_direction',
    }

    var recruiterParams = {};

    if (fetch_data.query?.typing == "recruiter") {
        for (var key in normal) {
            if (normal.hasOwnProperty(key)) {
                recruiterParams[key] = 'recruiter_' + normal[key];
            }
        }
    }
    else if (fetch_data.query?.typing == "candidate") {
        for (var key in normal) {
            if (normal.hasOwnProperty(key)) {
                recruiterParams[key] = 'candidate_' + normal[key];
            }
        }

    }
    else {
        recruiterParams = normal
    }
    console.log("key.BASE_API", keyss);



    var URL
    if (fetch_data.route_name == "/profile" || fetch_data.route_name == "/change_password") {

        URL = keyss.BASE_API + fetch_data.route_name
    }
    else if (endsWithQuestionMark) {
        var query_route = `${RouteName}${recruiterParams.per_page}=${fetch_data.query.per_page}&${recruiterParams.page}=${fetch_data.query.page}&${recruiterParams.search}=${fetch_data.query.search}&${recruiterParams.sort_direction}=${fetch_data.query.sort_direction}&${recruiterParams.sort_by}=${fetch_data.query.sort_by}`
        var SUB_URL = GET_URL + query_route + `&filters[from_date]=${fetch_data?.extra_date ? fetch_data.extra_date.from_date : ""}` + `&filters[to_date]=${fetch_data?.extra_date ? fetch_data.extra_date.to_date : ""}`
        URL = SUB_URL + `&filters[first_name]=${fetch_data?.cand_filter ? fetch_data.cand_filter.first_name : ""}` + `&filters[email]=${fetch_data?.cand_filter ? fetch_data.cand_filter.email : ""}` + `&filters[mobile]=${fetch_data?.cand_filter ? fetch_data.cand_filter.mobile : ""}` + `&filters[qualification]=${fetch_data?.cand_filter ? fetch_data.cand_filter.qualification : ""}`
    }
    else {
        URL = GET_URL  + fetch_data.route_name

    }

    // console.log("URL", URL)


    console.log("query_route", URL)

    // 

    // localStorage.setItem("loading", true);

    try {
        const Response = await axios({
            method: fetch_data.method,
            url: `${URL}`,
            headers: {
                Authorization: token,
            },
            data: fetch_data.body,
            responseType: fetch_data.res_type ? 'blob' : 'json',
        })

        // localStorage.setItem("loading", false);

        //  console.log("Response.data", Response)
        if (Response.status == 204) {
            Res.success = true
        }
        else {
            Res.success = Response.data
        }

        return Res

    }
    catch (err) {

        // localStorage.setItem("loading", false);

        console.log("err.response.data", err)

        Res.error = err.response.data
        return Res
    }


}
