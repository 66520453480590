import React, { useEffect, useState } from "react";
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import Select from 'react-select';
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";
import { ApiCall } from "../../../Config/API";
import { PaginationContent } from "../../../Components/PaginationContent";
import { Search_PageDrop } from "../../../Components/Search_PageDrop";
import { Link } from "react-router-dom/cjs/react-router-dom";
import moment from "moment";
// import Search from 'react-select';
import { saveAs } from 'file-saver';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import swal from "sweetalert";
import { TableHeader } from "../../../Components/TableHeader";

const Headoption = [

    { value: 'Robert', label: 'Robert' },

];


export const My_Shortlist = () => {





    const [query, setQuery] = useState({
        per_page: 10,
        page: 1,
        search: '',
        sort_by: "",
        sort_direction: 'desc'

    });


    useEffect(() => {

        getAll(query);

    }, []);
    const [selectlist, setSelectlist] = useState([]);
    const [shortList, setShortList] = useState([]);
    const [checkbox, setCheckbox] = useState(false);
    const [selectdate, setSelectdate] = useState('');

    const { dispatch } = useApiResponseContext();

    const getAll = async (query) => {


        console.log("query", query)
        var data = {
            "method": "get",
            "route_name": `my_short_lists?`,
            'query': query

        }
        const Response = await ApiCall(data)
        if (Response.success) {

            console.log("Response", Response.success)
            setShortList(Response.success.data)



            dispatch({ type: 'SUCCESS', payload: Response.success });

        }
        else {
            console.log("Error", Response.error.errors)
            dispatch({ type: 'ERROR', payload: Response.error });
        }

    };

    const PageOnpresFunc = (pagedrop, value,) => {

        var post_data = {
            per_page: pagedrop,
            page: value,
            search: query.search,
            sort_by: "",
            sort_direction: query.sort_direction
        }
        console.log("value", value)

        setQuery(post_data)

        getAll(post_data);

    }


    const OnSearchFunc = (value) => {


        var post_data = {
            per_page: query.per_page,
            page: 1,
            search: value,
            sort_by: "",
            sort_direction: ''
        }
        console.log("value", value)
        setQuery(post_data)
        getAll(post_data);

    }





    const PdfDownload = async (id) => {

        var data = {
            "method": "GET",
            "route_name": "candidates/" + id + '/files/resume',
            "res_type": 'blob'
        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response", Response.success)
            saveAs(Response.success, 'downloaded_resume.pdf');


        }
        else {
            console.log("Error", Response.error)

        }

    }
    const selectOption = async (id, e) => {


        // console.log("Assignnedlist", Assignnedlist)


        const data = [...selectlist]


        // Check if the ID is in the array
        if (data.includes(id)) {
            var ssidArray = selectlist.filter(item => item !== id);
            // console.log(ssidArray);
            setSelectlist(ssidArray)

        } else {
            data.push(id)

            // console.log("data", data)

            setSelectlist(data)


        }



    }



    const AssignMyShorlist = async () => {



        var data = {
            "method": "post",
            "route_name": "partner_short_lists",
            "body": {

                "short_list_ids": selectlist,
                "remind_date": selectdate



            }

        }

        const Response = await ApiCall(data)

        if (Response.success) {

            console.log("Response", Response.success)

            swal("Success!", "Candidate has been assigned to partner shortlist successfully.", "success")
            getAll(query)
            setCheckbox(true)
            setSelectlist([])
            setSelectdate('')
            setTimeout(() => {
                swal.close();
                setCheckbox(false)
            }, 1500);





        }
        else {
            console.log("Error", Response.error.errors)

        }



    }


    const handleDateChange = (date) => {
        setSelectdate(date);
    };

    const onSorting = (key, direct) => {


        var post_data = {
            per_page: query.per_page,
            page: query.page,
            search: '',
            sort_by: key ? key : '',
            sort_direction: direct
        }
        console.log("key", key)
        console.log("direct", direct)

        setQuery(post_data)
        getAll(post_data);

    }


    const TableHeadings = [
        {
            name: "S.No",
            icon: "Yes",
            sort: "desc",
        },
        {
            name: "Name",
        },
        {
            name: "Requirement No.",
        },
        {
            name: "Requirement Title",
        },
        {
            name: "Contact No.",
        },
        {
            name: "Qualification",
        },
        {
            name: "Resume",

        },
        {
            name: "Status",
        },
        {
            name: "Select On",
        },
     
        {
            name: "Action",
        },
        {
            name: "Partner Shortlist",
        },
    ]


  




    return (
        <div className="d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}


                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Shortlist Management</h4>
                        </div>




                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">List of Partner Shortlist</h6>
                                    <div>
                                        <Link type="button" className=" btn btn-default Organ_addbtn" to="/shortlists" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>


                                    </div>
                                </header>

                                <div className="row save-row">
                                    {/* <div className=""> */}
                                    <div className="col-2 " style={{ width: 150 }}>
                                        <h5 className="recordpage ">Remind Date</h5>
                                    </div>
                                    {/* <input value={selectdate} type="date" onChange={(e) => DateFunc(e)}></input> */}
                                    <div className="col-md-2">
                                        <DatePicker
                                            selected={selectdate}
                                            minDate={new Date()}
                                            onChange={handleDateChange}
                                            dateFormat="yyyy-MM-dd"

                                        />
                                    </div>
                                    {selectdate && selectlist.length > 0 ?
                                        <div className="col-md-2">
                                            <Link type="button" onClick={() => AssignMyShorlist()} className=" btn btn-default save-btn" ><i className="fa fa-save arrowkey"></i>Save</Link>
                                        </div>
                                        : null}

                                    {/* </div> */}

                                </div>

                                <Search_PageDrop OnSearch={(value) => OnSearchFunc(value)} PageDropClick={(value) => PageOnpresFunc(value, 1)} />




                                <div className="table-colorOrgan mob-overflow">
                                    <table >
                                        <TableHeader Titles={TableHeadings} Sortfunc={(key, direct) => onSorting(key, direct)} />


                                        {shortList.length > 0 ?
                                            <tbody>
                                                {shortList.map((e, i) => (
                                                    <tr className="tabledata">
                                                        <td style={{ width: 60 }}>{((query.page - 1) * 10 + 1) + i}</td>
                                                        <td style={{ color: 'blue' }}>{e.candidate.name}</td>
                                                        <td>{e.requirement.requirement_no}</td>
                                                        <td>{e.requirement.title}</td>
                                                        <td>{e.candidate.mobile}</td>
                                                        <td>{e.candidate.qualification}</td>
                                                        <td>
                                                            <Link onClick={() => PdfDownload(e.candidate.id)}>
                                                                <i className="fas fa-download"> Download</i>
                                                            </Link>

                                                        </td>

                                                        <td style={{ width: 113 }}>{e.requirement_status}</td>
                                                        <td style={{ width: 104 }}>{moment(e.requirement_assigned_at).format('MM-DD-YYYY hh:mm A')}</td>


                                                        <div className="dropdown history_dropdown">
                                                            <button className="dropdown_alert">
                                                                <span className="">▼</span>
                                                            </button>
                                                            <div className="dropdown-content">
                                                                <Link to={`/req_candidates_view/${e.requirement.id}/${e.candidate.id}`}> View</Link>


                                                            </div>
                                                        </div>


                                                        <td style={{ textAlign: "center" }}>
                                                            <input checked={checkbox ? false : undefined} type="checkbox" onChange={() => selectOption(e.id)} />

                                                        </td>

                                                    </tr>
                                                ))}

                                            </tbody>

                                            :

                                            <tbody>

                                                <tr style={{ textAlign: 'center' }}>
                                                    <td colSpan="10" style={{ textAlign: 'center', verticalAlign: 'middle' }}>No Record Found</td>
                                                </tr>
                                            </tbody>
                                        }
                                    </table>
                                </div>

                                <PaginationContent PageClick={(value) => PageOnpresFunc(query.per_page, value)} />



                            </section>

                        </div>










                    </div>
                </div>
            </div>
        </div>










    );
}

